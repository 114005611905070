// Facilitator mixins

%ee-phase-dialog-body-title {
  @extend %ee-dialog-separator;
  font-size: 1.375rem;
  line-height: 1;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 700;
  padding-right: 35px;
  padding-left: 35px;
}


// Player mixins

%ee-splash-view {
  height: 100%;
  z-index: 100;
  margin: 0 auto;
  width: 768px;
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-center;
}

%ee-banner-view {
  width: 450px;
  margin: 0 auto;
  background-image: image('public-black-transparent-bg.png');
  padding: 25px 30px 0 30px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 156px;
    width: 100%;
    @include background-image('public-content-after', 'png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  header {
    h1 {
      height: 155px;
      margin-bottom: 20px;
      @include background-image('logo', 'png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    h2 {
      @extend %h1;
      margin-bottom: 10px;
    }

    p {
      color: $white;
      text-align: center;
      margin-bottom: 15px;
    }
  }

  .ee-form {
    .ee-form-body {
      input {
        width: 280px;
      }
    }

    .ee-form-actions {
      margin-top: 15px;
      text-align: center;

      .btn {
        width: 325px;
        margin: 0 auto;

        &.ee-submit-btn {
          @include ee-button($teal, $white, $white);
          opacity: 1;
        }
      }
    }
  }
}

%ee-header-with-bolt {
  position: relative;

  &:after {
    @extend .ee-icon-bolt-horizontal;
    position: absolute;
    display: block;
    width: 100%;
    font-size: 6.25rem;
    line-height: 1;
    text-align: center;
    transform: rotate(-2deg);
    bottom: -57px;
    left: 0;
  }
}

%ee-city-data-bold {
  font-weight: 700;
  line-height: 1;
  letter-spacing: -5px;
}

%ee-city-data-label {
  font-size: 0.75rem;
  line-height: 0.9375rem;
  font-style: italic;
  text-transform: uppercase;
}

%ee-news-source {
  display: inline-block;
  position: relative;
  margin: 0 10px 0 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &.ee-news-source-ccn {
    top: 4px;
    height: 19px;
    width: 50px;
    @include background-image('player/logo-ccn', 'png');
  }

  &.ee-news-source-the-coastal-post {
    top: 5px;
    height: 22px;
    width: 99px;
    @include background-image('player/logo-coastal-post', 'png');
  }

  &.ee-news-source-the-coastal-times {
    top: 5px;
    height: 20px;
    width: 134px;
    @include background-image('player/logo-coastal-times', 'png');
  }

  &.ee-news-source-river-city-times {
    top: 4px;
    height: 19px;
    width: 151px;
    @include background-image('player/river-city-times', 'png');
  }

  &.ee-news-source-riverfront-news {
    top: 8px;
    height: 25px;
    width: 87px;
    @include background-image('player/riverfront-news', 'png');
  }

  &.ee-news-source-whitewater-post {
    top: 1px;
    height: 13px;
    width: 155px;
    @include background-image('player/whitewater-post', 'png');
  }

  &.ee-news-source-rock-city-times {
    top: 5px;
    height: 19px;
    width: 150px;
    background-image: image('player/logo-rock-city-times.png');
  }

  &.ee-news-source-midtown-gazette {
    top: 10px;
    height: 29px;
    width: 74px;
    background-image: image('player/logo-midtown-gazette.png');
  }

  &.ee-news-source-hillside-post {
    top: 2px;
    height: 13px;
    width: 118px;
    background-image: image('player/logo-hillside-post.png');
  }
}
