.ee-facilitator-page {
  .ee-phase-player-types {
    .ee-player-type-list-respond {
      .ee-player-type {
        &.ee-challenge-in-progress {
          &:before {
            content: '';
            position: absolute;
            top: -35px;
            z-index: 10;
            height: 69px;
            width: 100%;
            @include background-image('facilitator/respond-icon-challenge-in-progress', 'png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 64px 69px;
          }
        }
      }
    }

    .ee-citywide-respond {
      .btn {
        margin-left: -114px;
      }
    }
  }

  .ee-phase-dialog-respond {
    .ee-phase-dialog-content {
      .ee-phase-dialog-body {
        .ee-phase-dialog-send-details {
          text-align: left;
          position: relative;
          padding-right: 30px;
          padding-left: 65px;

          &:before {
            @extend .ee-icon-resource-card;
            position: absolute;
            top: 10px;
            left: 15px;
            font-size: 3.4375rem;
            color: $red;
          }
        }

        .ee-notification-sent-title {
          background-color: $red;

          &:before {
            color: darken($red, 10%);
          }
        }
      }

      .ee-phase-dialog-in-progress {
        .ee-phase-dialog-body {
          background: $red;

          .ee-notification-sent-title {
            border: 0;

            &:after {
              background: none;
              height: 0;
            }
          }

          .ee-notification-sent-message {
            color: $white;
            margin-top: 0;

            .ee-time-remaining-label {
              display: inline-block;
              margin-right: 10px;
              font-size: 0.6875rem;
              line-height: 1.5rem;
              font-style: italic;
              text-transform: uppercase;
            }

            .ee-time-remaining-value {
              font-size: 1.5rem;
              line-height: 1.5rem;

              .ee-char-0,
              .ee-char-1,
              .ee-char-3,
              .ee-char-4 {
                display: inline-block;
                width: 21px;
                padding: 6px 3px 1px 3px;
                margin-right: 1px;
                margin-left: 1px;
                color: $white;
                border-radius: 3px;
                background-color: darken($red, 15%);
              }

              .ee-char-2 {
                color: darken($red, 15%);
              }
            }
          }
        }

        .ee-phase-dialog-footer {
          background: $red;
        }
      }
    }
  }

  .ee-module-hurricane {
    .ee-phase-dialog-respond {
      .ee-phase-dialog-content {
        .ee-phase-dialog-list {
          .ee-phase-dialog-list-item {
            &-0 {
              .ee-phase-dialog-select-btn {
                &:before {
                  @extend .ee-icon-flood;
                }
              }
            }

            &-1 {
              .ee-phase-dialog-select-btn {
                &:before {
                  @extend .ee-icon-blackout;
                }
              }
            }

            &-2 {
              .ee-phase-dialog-select-btn {
                &:before {
                  @extend .ee-icon-gridlock;
                }
              }
            }

            &-3 {
              .ee-phase-dialog-select-btn {
                &:before {
                  @extend .ee-icon-road-closure;
                }
              }
            }
          }
        }

        .ee-phase-dialog-body {
          .ee-notification-sent-title {
            &.ee-challenge {
              &-flash-flood {
                &:before {
                  @extend .ee-icon-flood;
                }
              }

              &-blackout {
                &:before {
                  @extend .ee-icon-blackout;
                }
              }

              &-road-closure {
                &:before {
                  @extend .ee-icon-road-closure;
                }
              }

              &-gridlock {
                &:before {
                  @extend .ee-icon-gridlock;
                }
              }
            }
          }
        }
      }
    }
  }

  .ee-module-flood {
    .ee-phase-dialog-respond {
      .ee-phase-dialog-content {
        .ee-phase-dialog-list {
          .ee-phase-dialog-list-item {
            &-0 {
              .ee-phase-dialog-select-btn {
                &:before {
                  @extend .ee-icon-challenge-fire;
                }
              }
            }

            &-1 {
              .ee-phase-dialog-select-btn {
                &:before {
                  @extend .ee-icon-challenge-comms;
                }
              }
            }

            &-2 {
              .ee-phase-dialog-select-btn {
                &:before {
                  @extend .ee-icon-challenge-bridge;
                }
              }
            }

            &-3 {
              .ee-phase-dialog-select-btn {
                &:before {
                  @extend .ee-icon-challenge-compass;
                }
              }
            }
          }
        }

        .ee-phase-dialog-body {
          .ee-notification-sent-title {
            &.ee-challenge {
              &-explosion {
                &:before {
                  @extend .ee-icon-challenge-fire;
                }
              }

              &-mapping-glitch {
                &:before {
                  @extend .ee-icon-challenge-compass;
                }
              }

              &-tech-outage {
                &:before {
                  @extend .ee-icon-challenge-comms;
                }
              }

              &-bridge-closure {
                &:before {
                  @extend .ee-icon-challenge-bridge;
                }
              }
            }
          }
        }
      }
    }
  }

  .ee-module-earthquake {
    .ee-phase-dialog-respond {
      .ee-phase-dialog-content {
        .ee-phase-dialog-list {
          .ee-phase-dialog-list-item {
            &-0 {
              .ee-phase-dialog-select-btn {
                &:before {
                  @extend .ee-icon-fire;
                }
              }
            }

            &-1 {
              .ee-phase-dialog-select-btn {
                &:before {
                  @extend .ee-icon-medical-relief;
                }
              }
            }

            &-2 {
              .ee-phase-dialog-select-btn {
                &:before {
                  @extend .ee-icon-chasm;
                }
              }
            }

            &-3 {
              .ee-phase-dialog-select-btn {
                &:before {
                  @extend .ee-icon-train-derailment;
                }
              }
            }
          }
        }


        .ee-phase-dialog-body {
          .ee-notification-sent-title {
            &.ee-challenge {
              &-fire {
                &:before {
                  @extend .ee-icon-fire;
                }
              }

              &-emergency-aid {
                &:before {
                  @extend .ee-icon-medical-relief;
                }
              }

              &-chasm {
                &:before {
                  @extend .ee-icon-chasm;
                }
              }

              &-train-derailment {
                &:before {
                  @extend .ee-icon-train-derailment;
                }
              }
            }
          }
        }
      }
    }
  }
}
