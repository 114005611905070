.ee-facilitator-page {
  .ee-list-games-view {
    @extend .flex-grow-1;

    .ee-list-games-header {
      position: relative;
      margin-bottom: 20px;

      h1 {
        @extend %h1;
      }

      .ee-game-actions {
        position: absolute;
        right: 0;
        top: -10px;

        .btn {
          @extend %ee-button;
          @extend %ee-button-text;
          margin-left: 5px;
          padding-right: 20px;
          padding-left: 20px;

          &.ee-test-btn {
            @include ee-button(transparent, $teal, $white);
          }

          &.ee-start-btn {
            @include ee-button($teal, $teal, $white);
          }
        }
      }
    }

    .ee-no-games {
      @extend %ee-box;
      box-shadow: none;
      padding: 50px 20px 40px 20px;

      h2 {
        font-size: 1.75rem;
        line-height: 1;
        font-weight: 700;
      }

      h3 {
        font-size: 1.75rem;
        line-height: 1;
        font-weight: 400;
      }
    }

    .ee-game-list {
      @extend %ee-box;
      box-shadow: none;
      padding-top: 0;
      padding-bottom: 40px;
      border-bottom: 10px solid $teal;

      table {
        @extend .table;
        margin: 0;

        thead {
          tr {
            background-color: $grey-10;

            th {
              font-size: 0.875rem;
              color: $grey-35;
              border: 0;
              padding: 6px 10px 4px 10px;
              text-align: left;

              &:first-child {
                border-top-left-radius: 5px;
              }

              &:last-child {
                border-top-right-radius: 5px;
              }

              &.ee-game-type {
                padding-left: 20px;
              }
            }
          }
        }

        tbody {
          tr {
            border-bottom: 2px solid $grey-10;

            td {
              font-size: 1.125rem;
              line-height: 2.75rem;
              text-align: left;
              padding: 10px 10px 7px 10px;

              &.ee-game-type {
                padding-left: 35px;

                &:before {
                  font-size: 1.875rem;
                  line-height: 1;
                  margin-top: 5px;
                }

                &-hurricane {
                  &:before {
                    @extend .ee-icon-hurricane;
                    color: $red;
                  }
                }

                &-flood {
                  &:before {
                    @extend .ee-icon-flood;
                    color: $teal;
                  }
                }

                &-earthquake {
                  &:before {
                    @extend .ee-icon-earthquake;
                    color: $yellow;
                  }
                }
              }

              &.ee-game-actions {
                width: 400px;

                .ee-game-complete {
                  position: relative;
                  padding-left: 55px;
                  line-height: 2.75rem;
                  width: 48%;

                  &:before {
                    content: '';
                    position: absolute;
                    display: block;
                    top: -17px;
                    height: 50px;
                    width: 50px;
                    @include background-image('facilitator/icon-alert', 'png');
                    background-repeat: no-repeat;
                    background-size: 50px 50px;
                  }
                }

                .btn {
                  @extend %ee-button;
                  @extend %ee-button-text;
                  margin-left: 2%;
                  width: 48%;

                  &:first-child {
                    margin-left: 0;
                  }
                }

                .ee-reconnect-btn {
                  @include ee-button($white, $teal, $teal, false);
                }

                .ee-end-btn {
                  @include ee-button($white, $red, $red, false);
                }
              }
            }
          }
        }
      }
    }
  }
}
