.ee-tooltip-wrapper {
  .popup-overlay {
    background-color: $opacity-50;
    z-index: 100;
  }

  .popup-content {
    width: auto !important;
    border: 0 !important;
    border-bottom: 3px solid $grey-20 !important;
    box-shadow: none !important;
    border-radius: 5px;
    left: auto !important;
    z-index: 101 !important;

    > div {
      &:first-child {
        display: none !important;
      }
    }

    &:before {
      content: '';
      left: 50%;
      bottom: -31px;
      position: absolute;
      border: 16px solid transparent;
      border-top-width: 13px;
      border-top-color: $grey-20;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -27px;
      border: 15px solid transparent;
      border-top-width: 12px;
      border-top-color: $white;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      text-align: center;
      font-size: 1rem;
      color: $dark-blue;
      font-weight: 800;
      font-style: italic;
      text-transform: uppercase;
      white-space: nowrap;
      padding: 15px 15px 10px 15px;
      border-bottom: 1px solid $grey-05;

      a,
      .btn-link {
        color: inherit;
        font-weight: inherit;
        font-style: inherit;
        padding: 0;
        border: 0;
        border-radius: 0;
        transition: none;
        position: relative;

        &:hover {
          text-decoration: none;
        }

        &:after {
          content: '';
          position: absolute;
          top: -15px;
          right: -15px;
          bottom: -10px;
          left: -15px;
        }
      }

      &:last-child {
        border: 0;
      }
    }
  }
}

.ee-facilitator-page {
  .ee-game-settings {
    .popup-content {
      top: -115px !important;
      right: 15px !important;

      &:before {
        margin-left: -16px;
      }

      &:after {
        margin-left: -15px;
      }
    }

    ul {
      li {
        &:first-child {
          position: relative;

          &:after {
            @extend .ee-icon-bolt-horizontal;
            color: $grey-05;
            position: absolute;
            left: 0;
            bottom: 62px;
            height: 16px;
            font-size: 10rem;
            line-height: 1;
            width: 100%;
          }
        }
      }
    }
  }
}

.ee-player-page {
  .ee-game-settings {
    .popup-content {
      top: -60px !important;
      right: 20px !important;

      &:before {
        margin-left: 30px;
      }

      &:after {
        margin-left: 31px;
      }
    }
  }
}
