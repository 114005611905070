.ee-player-page {
  .ee-lobby-view {
    height: 100%;

    .ee-full-page-background {
      background-image: image('global-background.jpg');

      .ee-full-page-double-background {
        &:after {
          @include background-image('player/connecting-bolt-background', 'png');
        }
      }
    }

    .ee-connecting {
      @extend %ee-splash-view;

      h1 {
        @extend %h1;
        font-size: 5rem;
        line-height: 1;
        text-shadow: 3px 3px $opacity-25;
        text-indent: -210px;
        font-kerning: normal;
        font-weight: 700;
        letter-spacing: normal;
        transform: rotate(-8deg) skew(-9deg, 0deg);
        margin-bottom: 120px;

        strong {
          display: block;
          font-size: 7.5rem;
          font-weight: 800;
          text-indent: 105px;
        }

        &:after {
          @extend .ee-icon-bolt-horizontal;
          position: absolute;
          bottom: -190px;
          left: 50%;
          font-size: 21.875rem;
          line-height: 1;
          color: $red;
          text-indent: -190px;
        }
      }

      .ee-connecting-message {
        @extend %ee-warn-box;
        padding-bottom: 80px;
        font-size: 1.375rem;
        line-height: 1.625rem;
        text-align: center;
      }
    }
  }
}
