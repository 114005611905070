@keyframes ee-animate-bounce-in {
  0% { opacity: 0; transform: scale(.5); }
  60% { opacity: 1; transform: scale(1.15); }
  100% { opacity: 1; transform: scale(1); }
}

@keyframes ee-animate-light-bounce-in {
  0% { opacity: 0; transform: scale(.5); }
  100% { opacity: 1; transform: scale(1); }
}

@keyframes earthquake-shake {
  0%, 24% { transform: translate(0, 0) rotate(0); }
  26% { transform: translate(8px, 0px) rotate(-1.5deg); }
  28% { transform: translate(0px, -12px) rotate(2.5deg); }
  30% { transform: translate(-1px, 11px) rotate(3.5deg); }
  32% { transform: translate(10px, -15px) rotate(2.5deg); }
  34% { transform: translate(-11px, 17px) rotate(0.5deg); }
  36% { transform: translate(-18px, -12px) rotate(1.5deg); }
  38% { transform: translate(-19px, 18px) rotate(2.5deg); }
  40% { transform: translate(8px, 0px) rotate(-1.5deg); }
  42% { transform: translate(0px, -12px) rotate(2.5deg); }
  44% { transform: translate(-1px, 11px) rotate(3.5deg); }
  46% { transform: translate(10px, -15px) rotate(2.5deg); }
  48% { transform: translate(-11px, 17px) rotate(0.5deg); }
  50% { transform: translate(-18px, -12px) rotate(1.5deg); }
  52% { transform: translate(-19px, 18px) rotate(2.5deg); }
  54% { transform: translate(12px, 19px) rotate(-2.5deg); }
  56% { transform: translate(-12px, 10px) rotate(2.5deg); }
  58% { transform: translate(11px, 11px) rotate(-2.5deg); }
  60% { transform: translate(20px, -19px) rotate(-0.5deg); }
  62% { transform: translate(15px, 14px) rotate(0.5deg); }
  64% { transform: translate(12px, -14px) rotate(0.5deg); }
  66% { transform: translate(-19px, -17px) rotate(2.5deg); }
  68% { transform: translate(-15px, -11px) rotate(0.5deg); }
  70% { transform: translate(19px, -11px) rotate(2.5deg); }
  72% { transform: translate(18px, 18px) rotate(-1.5deg); }
  74% { transform: translate(-11px, 14px) rotate(2.5deg); }
  76% { transform: translate(13px, -17px) rotate(1.5deg); }
  78% { transform: translate(-17px, 0px) rotate(-2.5deg); }
  80% { transform: translate(-15px, 15px) rotate(2.5deg); }
  82% { transform: translate(16px, 16px) rotate(-0.5deg); }
  84% { transform: translate(20px, 18px) rotate(-0.5deg); }
  86% { transform: translate(14px, 20px) rotate(-0.5deg); }
  88% { transform: translate(-15px, -19px) rotate(-1.5deg); }
  90% { transform: translate(-18px, 19px) rotate(1.5deg); }
  92% { transform: translate(16px, 16px) rotate(-1.5deg); }
  94% { transform: translate(-13px, -14px) rotate(-1.5deg); }
  96% { transform: translate(16px, -15px) rotate(0.5deg); }
  98% { transform: translate(20px, 14px) rotate(-0.5deg); }
  100% { transform: translate(0, 0) rotate(0); }
}

@keyframes ee-event-tracker-pulse-1 {
  0% { width: 0; height: 0; }
  16% { width: 1000%; height: 1000%; }
  33%, 100% { width: 10000%; height: 10000%; }
}

@keyframes ee-event-tracker-pulse-2 {
  0%, 1.8% { width: 0; height: 0; }
  16%, 18% { width: 660%; height: 660%; }
  33%, 100% { width: 6600%; height: 6600%; }
}

@keyframes ee-event-tracker-pulse-3 {
  0%, 3% { width: 0; height: 0; }
  16%, 20% { width: 330%; height: 330%; }
  33%, 100% { width: 3300%; height: 3300%; }
}

@keyframes ee-event-tracker-topography {
  0% { background-position: 0 0; }
  100% { background-position: 999999px 0; }
}

@keyframes ee-event-tracker-pulse-small {
  0% { transform: translate(-50%,-50%) scale(0); opacity: 0; }
  50% { opacity: 1; }
  100% { transform: translate(-50%,-50%) scale(0.5); opacity: 0; }
}

@keyframes ee-score-panel-animate-score-in {
  0% { transform: scale(.5); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes ee-score-panel-animate-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes floatingTooltipVertical {
  0% { transform: translateY(-10px); }
  50% { transform: translateY(10px); }
  100% { transform: translateY(-10px); }
}

@keyframes floatingTooltipHorizontal {
  0% { transform: translateX(-10px); }
  50% { transform: translateX(10px); }
  100% { transform: translateX(-10px); }
}


.ee-animate {
  backface-visibility: hidden;
}
