.ee-player-page {
  .ee-player-recover-view {
    .ee-player-footer {
      .ee-buttons-left {
        .btn {
          display: none;
        }
      }
    }

    .ee-player-game-wrapper {
      background-image: image('global-background.jpg');
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      .ee-scoring-view {
        @extend %ee-splash-view;
        width: 900px;
        position: relative;
      }

      .ee-scoring-overview {
        h1 {
          transform: rotate(-8deg) skew(-9deg, 0deg);
          font-size: 5rem;
          line-height: 1;
          font-weight: 400;
          text-shadow: 3px 3px $opacity-25;

          strong {
            display: block;
            font-weight: 800;
            letter-spacing: -2px;
          }

          &:after {
            font-size: 21.875rem;
            bottom: -190px;
          }
        }

        &.ee-animate-exit {
          opacity: 1;

          &-active {
            opacity: 0;
            transition: opacity 600ms cubic-bezier(.02, .01, .47, 1); // default jquery animate approximation (swing easing)
          }
        }
      }

      .ee-scoring-neighborhood {
        border-radius: 5px;
        position: relative;
        background-color: $white;
        position: absolute;
        opacity: 0;

        &.ee-animate-enter {
          &-done {
            opacity: 1;
            transition: opacity 600ms cubic-bezier(.02, .01, .47, 1);
          }
        }

        &.ee-animate-exit {
          opacity: 1;

          &-active {
            opacity: 0;
            transition: opacity 600ms cubic-bezier(.02, .01, .47, 1);
          }
        }
      }

      .ee-scoring-header {
        height: 105px;
        background-size: cover;
        border-radius: 5px 5px 0 0;

        .ee-scoring-player {
          position: relative;
          top: -40px;
          width: 150px;
          height: 120px;
          margin: 0 auto;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .ee-scoring-question {
        font-size: 1.375rem;
        line-height: 1.5rem;
        font-weight: 700;
        text-align: center;
        color: $grey-70;
        padding: 30px 35px;
      }

      .ee-scoring-options {
        @extend .d-flex;
        @extend .flex-row;
        list-style-type: none;
        margin: 0;
        padding: 30px 0;

        .ee-scoring-option {
          width: 50%;
          padding-left: 54px;
          padding-right: 140px;
          border-right: 3px solid $grey-05;
          font-size: 1.25rem;
          line-height: 1.25rem;
          color: $grey-10;
          font-weight: 700;
          position: relative;

          &:after {
            position: absolute;
            right: 24px;
            color: $grey-10;
            min-width: 100px;
            text-align: center;
            top: 50%;
            margin-top: -10px;
            font-size: 3.125rem;
          }

          &:last-child {
            border: 0;
          }

          &-selected {
            color: $green;

            &:after {
              color: $green;
            }
          }
        }
      }

      .ee-scoring-total {
        border-top: 3px solid $grey-05;
        @include background-image('player/diagonal-lines', 'png');
        background-size: 13px 13px;
        position: relative;
        height: 175px;
        z-index: 0;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;

        ul {
          @extend .d-flex;
          @extend .flex-row;
          list-style-type: none;
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;

          li {
            flex: 1 0 10%;
            border-right: 3px solid $grey-05;
            font-size: 1.25rem;
            line-height: 10.9375rem;
            font-weight: 900;
            color: $grey-10;
            text-align: center;

            &:first-child {
              flex: 1 1;
              border-bottom-left-radius: 5px;
            }

            &:last-child {
              border: 0;
              border-bottom-right-radius: 5px;
            }

            &.ee-animate {
              opacity: 0;
              animation-name: ee-score-panel-animate-in;
              animation-duration: .5s;
              animation-timing-function: ease-out;
              animation-fill-mode: forwards;

              &:nth-child(1) {
                animation-delay: 2s;
              }

              &:nth-child(2) {
                animation-delay: 2.2s;
              }

              &:nth-child(3) {
                animation-delay: 2.4s;
              }

              &:nth-child(4) {
                animation-delay: 2.6s;
              }

              &:nth-child(5) {
                animation-delay: 2.8s;
              }

              &:nth-child(6) {
                animation-delay: 3s;
              }
            }
          }

          &.ee-scoring-total-background {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
          }

          &.ee-scoring-total-score {
            li {
              &.ee-score-achieved,
              &.ee-score-final {
                font-size: 1.875rem;
                color: $white;
                background-color: $green;
                border-right-color: $shade-green-10;

                &.ee-animate {
                  transform: scale(.5);
                  animation-name: ee-score-panel-animate-score-in;
                }
              }

              &.ee-score-achieved {
                &:before {
                  @extend .ee-icon-check;
                  margin-top: -10px;
                }
              }
            }
          }
        }
      }

      @media (max-height: 767px) {
        .ee-scoring-total {
          height: 155px;
        }
      }

      .ee-scoring-city {
        margin-bottom: 95px;

        .btn {
          &:disabled {
            opacity: 1;
          }
        }

        .ee-player-type-list {
          position: relative;
          height: 500px;
          width: 100%;
          list-style: none;
          padding: 0;
          margin: 0;
          @include background-image('facilitator/hexagon', 'png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 365px;

          &.ee-animate {
            opacity: 0;
            animation-name: ee-animate-bounce-in;
            animation-duration: .5s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
          }

          .ee-player-type {
            position: absolute;

            button {
              width: 158px;
              height: 131px;
              position: relative;
              z-index: 10;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }

            &.ee-animate {
              opacity: 0;
              animation-name: ee-animate-bounce-in;
              animation-duration: 0.5s;
              animation-timing-function: ease-out;
              animation-fill-mode: forwards;
            }

            &-0 {
              top: 0;
              left: 50%;
              margin-left: -79px;

              &.ee-animate {
                animation-delay: 1s;
              }
            }

            &-1 {
              top: 85px;
              right: 50%;
              margin-right: -250px;

              &.ee-animate {
                animation-delay: 1.2s;
              }
            }

            &-2 {
              bottom: 91px;
              right: 50%;
              margin-right: -250px;

              &.ee-animate {
                animation-delay: 1.4s;
              }
            }

            &-3 {
              bottom: 0;
              left: 50%;
              margin-left: -79px;

              &.ee-animate {
                animation-delay: 1.6s;
              }
            }

            &-4 {
              bottom: 91px;
              left: 50%;
              margin-left: -250px;

              &.ee-animate {
                animation-delay: 1.8s;
              }
            }

            &-5 {
              top: 85px;
              left: 50%;
              margin-left: -250px;

              &.ee-animate {
                animation-delay: 2s;
              }
            }

            .ee-neighborhood-score {
              position: absolute;
              left: 50%;
              width: 60px;
              padding-top: 25px;
              margin-top: 35px;
              margin-left: -30px;

              &:before,
              &:after {
                @extend .ee-icon-banner;
                position: absolute;
                line-height: 1;
              }

              &:before {
                top: 5px;
                left: -2px;
                z-index: 2;
                font-size: 4.0625rem;
              }

              &:after {
                top: 0;
                left: -8px;
                z-index: 1;
                font-size: 4.8125rem;
              }

              &-value {
                position: relative;
                z-index: 10;
                display: block;
                font-size: 0.5rem;
                line-height: 1;
                font-weight: 400;
                font-style: italic;
                text-align: center;
                text-transform: uppercase;
                color: rgba(255, 255, 255, 0.7);

                strong {
                  display: block;
                  font-size: 1.5rem;
                  font-weight: 800;
                  font-style: normal;
                  letter-spacing: -1px;
                  color: $white;
                }
              }
            }
          }
        }

        .ee-citywide-recover {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 200px;
          margin-top: -95px;
          margin-left: -100px;
          text-align: center;
          font-size: 0.625rem;
          line-height: 1;
          font-weight: 400;
          font-style: italic;
          color: $white;
          text-transform: uppercase;

          &:before {
            content: '';
            display: block;
            width: 81px;
            height: 51px;
            @include background-image('citywide-score', 'png');
            background-size: 81px 51px;
            margin-left: auto;
            margin-right: auto;
          }

          strong {
            position: relative;
            top: 8px;
            display: block;
            font-size: 3.25rem;
            font-style: normal;
            font-weight: 800;
            letter-spacing: -1px;
          }

          &.ee-animate {
            opacity: 0;
            animation-name: ee-animate-light-bounce-in;
            animation-duration: .5s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
            animation-delay: 2.5s;
          }
        }
      }
    }
  }

  .ee-module-hurricane {
    .ee-player-recover-view {
      .ee-scoring-neighborhood {
        &-midtown {
          .ee-scoring-header {
            background-color: $green;
            @include background-image('player/neighborhood-beacon-midtown-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-midtown', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-challenge-move;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-challenge-supplies;
                }
              }
            }
          }
        }

        &-sunnybrook {
          .ee-scoring-header {
            background-color: $teal;
            @include background-image('player/neighborhood-beacon-sunnybrook-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-challenge-move;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-challenge-supplies;
                }
              }
            }
          }
        }

        &-downtown {
          .ee-scoring-header {
            background-color: $purple;
            @include background-image('player/neighborhood-beacon-downtown-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-downtown', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-challenge-family;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-challenge-shelter;
                }
              }
            }
          }
        }

        &-hillside {
          .ee-scoring-header {
            background-color: $yellow;
            @include background-image('player/neighborhood-beacon-hillside-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-hillside', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-challenge-route;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-challenge-shelter;
                }
              }
            }
          }
        }

        &-meadowglen {
          .ee-scoring-header {
            background-color: $blue;
            @include background-image('player/neighborhood-beacon-meadowglen-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-meadowglen', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-challenge-supplies;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-challenge-move-supplies;
                  font-size: 4.375rem;
                }
              }
            }
          }
        }

        &-waterview {
          .ee-scoring-header {
            background-color: $blue;
            @include background-image('player/neighborhood-beacon-waterview-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-waterview', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-challenge-flood;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-challenge-flood;
                }
              }
            }
          }
        }
      }

      .ee-player-type-list-recover {
        .ee-player-type {
          &-midtown {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-midtown', 'png');
            }

            .ee-neighborhood-score {
              top: 70px;

              &:before {
                color: darken($green, 10%);
              }

              &:after {
                color: $green;
              }
            }
          }

          &-sunnybrook {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-sunnybrook', 'png');
            }

            .ee-neighborhood-score {
              top: 67px;

              &:before {
                color: darken($teal, 10%);
              }

              &:after {
                color: $teal;
              }
            }
          }

          &-downtown {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-downtown', 'png');
            }

            .ee-neighborhood-score {
              top: 69px;

              &:before {
                color: darken($purple, 10%);
              }

              &:after {
                color: $purple;
              }
            }
          }

          &-hillside {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-hillside', 'png');
            }

            .ee-neighborhood-score {
              top: 69px;

              &:before {
                color: darken($yellow, 10%);
              }

              &:after {
                color: $yellow;
              }
            }
          }

          &-meadowglen {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-meadowglen', 'png');
            }

            .ee-neighborhood-score {
              top: 77px;

              &:before {
                color: darken($blue, 10%);
              }

              &:after {
                color: $blue;
              }
            }
          }

          &-waterview {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-waterview', 'png');
            }

            .ee-neighborhood-score {
              top: 69px;

              &:before {
                color: darken($blue, 10%);
              }

              &:after {
                color: $blue;
              }
            }
          }
        }
      }
    }
  }

  .ee-module-flood {
    .ee-player-recover-view {
      .ee-scoring-neighborhood {
        &-hillside {
          .ee-scoring-header {
            background-color: $yellow;
            @include background-image('player/neighborhood-beacon-hillside-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-hillside', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-challenge-shelter;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-challenge-move-supplies;
                  font-size: 4.375rem;
                }
              }
            }
          }
        }

        &-meadowglen {
          .ee-scoring-header {
            background-color: $blue;
            @include background-image('player/neighborhood-beacon-meadowglen-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-meadowglen', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-challenge-flood;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-challenge-flood;
                }
              }
            }
          }
        }

        &-downtown {
          .ee-scoring-header {
            background-color: $purple;
            @include background-image('player/neighborhood-beacon-downtown-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-downtown', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-hardhat;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-tools;
                  font-size: 2.5rem;
                }
              }
            }
          }
        }

        &-waterview {
          .ee-scoring-header {
            background-color: $blue;
            @include background-image('player/neighborhood-beacon-waterview-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-waterview', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-run;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-tools;
                  font-size: 2.5rem;
                }
              }
            }
          }
        }

        &-sunnybrook {
          .ee-scoring-header {
            background-color: $teal;
            @include background-image('player/neighborhood-beacon-sunnybrook-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-run;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-challenge-shelter;
                }
              }
            }
          }
        }

        &-midtown {
          .ee-scoring-header {
            background-color: $green;
            @include background-image('player/neighborhood-beacon-midtown-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-midtown', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-challenge-move-supplies;
                  font-size: 4.375rem;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-water-block;
                  font-size: 5rem;
                }
              }
            }
          }
        }
      }

      .ee-player-type-list-recover {
        .ee-player-type {
          &-hillside {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-hillside', 'png');
            }

            .ee-neighborhood-score {
              top: 76px;

              &:before {
                color: darken($yellow, 10%);
              }

              &:after {
                color: $yellow;
              }
            }
          }

          &-meadowglen {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-meadowglen', 'png');
            }

            .ee-neighborhood-score {
              top: 74px;

              &:before {
                color: darken($blue, 10%);
              }

              &:after {
                color: $blue;
              }
            }
          }

          &-downtown {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-downtown', 'png');
            }

            .ee-neighborhood-score {
              top: 69px;

              &:before {
                color: darken($purple, 10%);
              }

              &:after {
                color: $purple;
              }
            }
          }

          &-waterview {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-waterview', 'png');
            }

            .ee-neighborhood-score {
              top: 69px;

              &:before {
                color: darken($blue, 10%);
              }

              &:after {
                color: $blue;
              }
            }
          }

          &-sunnybrook {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-sunnybrook', 'png');
            }

            .ee-neighborhood-score {
              top: 72px;

              &:before {
                color: darken($teal, 10%);
              }

              &:after {
                color: $teal;
              }
            }

          }

          &-midtown {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-midtown', 'png');
            }

            .ee-neighborhood-score {
              top: 70px;

              &:before {
                color: darken($green, 10%);
              }

              &:after {
                color: $green;
              }
            }
          }
        }
      }
    }
  }

  .ee-module-earthquake {
    .ee-player-recover-view {
      .ee-scoring-neighborhood {
        &-midtown {
          .ee-scoring-header {
            background-color: $green;
            @include background-image('player/neighborhood-beacon-midtown-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-midtown', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-challenge-shelter;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-challenge-caregivers;
                }
              }
            }
          }
        }

        &-waterview {
          .ee-scoring-header {
            background-color: $blue;
            @include background-image('player/neighborhood-beacon-waterview-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-waterview', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-challenge-flood;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-challenge-flood;
                }
              }
            }
          }
        }

        &-hillside {
          .ee-scoring-header {
            background-color: $yellow;
            @include background-image('player/neighborhood-beacon-hillside-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-hillside', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-hardhat;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-challenge-row;
                }
              }
            }
          }
        }

        &-sunnybrook {
          .ee-scoring-header {
            background-color: $teal;
            @include background-image('player/neighborhood-beacon-sunnybrook-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-medical-relief;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-run;
                }
              }
            }
          }
        }

        &-downtown {
          .ee-scoring-header {
            background-color: $purple;
            @include background-image('player/neighborhood-beacon-downtown-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-downtown', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-fire;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-medical-relief;
                }
              }
            }
          }
        }

        &-meadowglen {
          .ee-scoring-header {
            background-color: $blue;
            @include background-image('player/neighborhood-beacon-meadowglen-background', 'png');

            .ee-scoring-player {
              @include background-image('player/neighborhood-beacon-meadowglen', 'png');
            }
          }

          .ee-scoring-options {
            .ee-scoring-option {
              &-0 {
                &:after {
                  @extend .ee-icon-challenge-shelter;
                }
              }

              &-1 {
                &:after {
                  @extend .ee-icon-challenge-route;
                }
              }
            }
          }
        }
      }

      .ee-player-type-list-recover {
        .ee-player-type {
          &-midtown {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-midtown', 'png');
            }

            .ee-neighborhood-score {
              top: 70px;

              &:before {
                color: darken($green, 10%);
              }

              &:after {
                color: $green;
              }
            }
          }

          &-waterview {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-waterview', 'png');
            }

            .ee-neighborhood-score {
              top: 77px;

              &:before {
                color: darken($blue, 10%);
              }

              &:after {
                color: $blue;
              }
            }
          }

          &-hillside {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-hillside', 'png');
            }

            .ee-neighborhood-score {
              top: 80px;

              &:before {
                color: darken($yellow, 10%);
              }

              &:after {
                color: $yellow;
              }
            }
          }

          &-sunnybrook {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-sunnybrook', 'png');
            }

            .ee-neighborhood-score {
              top: 69px;

              &:before {
                color: darken($teal, 10%);
              }

              &:after {
                color: $teal;
              }
            }
          }

          &-downtown {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-downtown', 'png');
            }

            .ee-neighborhood-score {
              top: 69px;

              &:before {
                color: darken($purple, 10%);
              }

              &:after {
                color: $purple;
              }
            }
          }

          &-meadowglen {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-meadowglen', 'png');
            }

            .ee-neighborhood-score {
              top: 77px;

              &:before {
                color: darken($blue, 10%);
              }

              &:after {
                color: $blue;
              }
            }
          }
        }
      }
    }
  }
}
