.ee-facilitator-page {
  .ee-choose-scenario-view,
  .ee-game-lobby-view,
  .ee-game-lobby-test-view {
    @extend .flex-grow-1;

    h1 {
      @extend %h1;
      font-weight: 500;
      text-shadow: 0 2px 0 $opacity-15;
      letter-spacing: -1px;
      margin-bottom: 22px;
      text-align: center;
    }

    .ee-continue-btn {
      @extend %ee-button;
      @extend %ee-button-text;
      @include ee-button($teal, $white, $white);
      margin: 40px auto;
      width: 100%;
      max-width: 500px;
      display: block;
    }
  }

  .ee-choose-scenario-view {
    color: $white;

    h1 {
      text-transform: none;
    }

    .ee-scenario {
      transition: all 0.25s ease;
      display: inline-block;
      width: 239px;
      height: 279px;
      padding-top: 195px;
      margin: 5px;
      background-color: transparent;
      background-size: 100%;
      border-radius: 5px;
      font-size: 1.75rem;
      text-transform: uppercase;
      font-weight: 500;
      font-style: italic;
      transform: translateY(0);

      &:hover {
        cursor: pointer;
      }

      &-selected {
        box-shadow: 0 2px 10px 4px $opacity-20;
        transform: translateY(-10px);
      }

      &-hurricane {
        @include background-image('facilitator/module-hurricane', 'png');

        &:hover:not(&-selected) {
          background-color: transparentize($red, 0.75);
        }

        &-selected {
          background-color: transparentize($red, 0.65);
        }
      }

      &-flood {
        @include background-image('facilitator/module-flood', 'png');

        &:hover:not(&-selected) {
          background-color: transparentize($teal, 0.75);
        }

        &-selected {
          background-color: transparentize($teal, 0.65);
        }
      }

      &-earthquake {
        @include background-image('facilitator/module-earthquake' ,'png');

        &:hover:not(&-selected) {
          background-color: transparentize($orange, 0.75);
        }

        &-selected {
          background-color: transparentize($orange, 0.65);
        }
      }
    }
  }

  .ee-game-lobby-test-view,
  .ee-game-lobby-view {
    .ee-game-steps {
      @include make-container();
      color: $white;
    }

    .ee-game-step {
      @include make-row();
      @extend .align-items-center;
      padding: 20px 0;
      border-top: 1px solid transparentize($white, 0.9);

      &:last-child {
        border-bottom: 1px solid transparentize($white, 0.9);
      }
    }

    .ee-step-number {
      @include make-col-ready();
      @include make-col(1);
      font-size: 4rem;
      line-height: 4rem;
      font-weight: 800;
      text-align: center;
    }

    .ee-step-caption {
      @include make-col-ready();
      @include make-col(2);
      font-size: 0.875rem;
      line-height: 1.125rem;
      font-weight: 700;
      text-transform: uppercase;
      font-style: italic;
    }

    .ee-step-data {
      @include make-col-ready();
      @include make-col(6);
    }

    .ee-step-help {
      @include make-col-ready();
      @include make-col(3);
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }

  .ee-game-lobby-test-view {
    h1 {
      font-size: 2.5rem;
      font-style: normal;
      text-transform: none;
    }

    p {
      color: $white;
      text-align: center;
      width: 85%;
      max-width: 600px;
      margin: 0 auto 40px auto;
    }

    img {
      max-width: 250px;
      margin: 0 auto 40px auto;
      display: block;
    }

    .ee-step-data {
      .btn {
        @extend %ee-button;
        @extend %ee-button-text;
        width: 100%;
        max-width: 500px;
        display: block;
        margin: 0 auto;

        &.ee-player-btn {
          @include ee-button(transparent, $white, $white);
        }

        &.ee-start-btn {
          @include ee-button($teal, $white, $white);
        }
      }
    }
  }

  .ee-game-lobby-view {
    .ee-game-link {
      text-align: center;
      padding-bottom: 6px;

      .ee-url {
        font-size: 0.875rem;
        line-height: 1.125rem;
        font-weight: 700;
        text-transform: uppercase;
        font-style: italic;
        display: inline-block;
      }

      .btn {
        @extend %ee-button;
        @extend %ee-button-text;
        @include ee-button(transparent, $white, $white);
        display: inline-block;
        margin-left: 10px;
      }
    }

    .ee-connected-participants {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      margin-top: -30px;
      text-align: center;
      font-size: 2.25rem;
      line-height: 3.6875rem;
      font-style: italic;

      strong {
        font-size: 3.6875rem;
        font-style: normal;
        position: relative;
        top: 10px;
      }
    }

    .ee-participant-list {
      list-style-type: none;
      position: relative;
      margin: 0 auto;
      padding: 0;
      height: 195px;
      width: 325px;
      @include background-image('facilitator/start-game-connected-devices-background', 'png');
      background-repeat: no-repeat;
      background-size: 194px 168px;
      background-position: center;
    }

    .ee-participant {
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 100px;
      border: 3px solid rgba(255, 255, 255, 0.2);
      background-color: $opaque-dark-blue;

      &-connected {
        background-color: $green;

        &:before {
          @extend .ee-icon-check;
          color: $white;
          font-size: 1.125rem;
          margin-top: 2px;
          margin-left: 2px;
        }
      }
    }

    .ee-participant-1 {
      top: 0;
      left: 103px;
    }

    .ee-participant-2 {
      top: 0;
      right: 103px;
    }

    .ee-participant-3 {
      top: 80px;
      left: 58px;
    }

    .ee-participant-4 {
      top: 80px;
      right: 58px;
    }

    .ee-participant-5 {
      bottom: 0;
      left: 103px;
    }

    .ee-participant-6 {
      bottom: 0;
      right: 103px;
    }

    .ee-popup {
      width: 514px;
    }
  }
}
