.ee-player-page {
  overflow: hidden;

  .ee-game-play-modal {
    .ee-popup-title {
      h1 {
        text-align: left;
      }
    }
  }

  .ee-player-view {
    min-width: 1024px;
  }

  .ee-player-game-wrapper {
    @extend .d-flex;
    @extend .flex-row;
    height: calc(100% - 95px);
    position: relative;

    header {
      h1 {
        @extend %ee-header-with-bolt;
        @extend %h1;
        font-size: 1.75rem;
        line-height: 1.25rem;
        text-shadow: none;
        margin-bottom: 30px;
        font-weight: 700;
        text-shadow: 2px 2px 0 $opacity-10;
        letter-spacing: normal;

        &:after {
          color: $red;
        }
      }
    }

    .ee-player-content {
      @extend .d-flex;
      width: 63%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      color: $white;
    }

    .ee-player-aside {
      @extend .d-flex;
      @extend .flex-column;
      width: 37%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }

  .ee-splash-view {
    @extend %ee-splash-view;
  }

  .ee-player-type-overview {
    .ee-player-type {
      margin-top: 40px;
      margin-bottom: 40px;
      height: 375px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .ee-player-type-information {
      margin-bottom: 20px;
    }

    @media (max-height: 767px) {
      .ee-player-type {
        margin-top: 25px;
        height: 300px;
      }

      .ee-player-type-information {
        margin-bottom: 10px;
      }
    }
  }

  .ee-player-footer {
    @include make-row(0);
    @extend .flex-shrink-0;
    padding: 26px 30px;
    background-color: $white;
    border-top: 3px solid $grey-05;
    position: relative;
    z-index: 1;
    height: 95px;

    &:before {
      content: '';
      position: absolute;
      top: -39px;
      right: 50%;
      display: block;
      width: 138px;
      height: 39px;
      margin-right: -68px;
      @include background-image('player/participant-footer-before', 'png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    .ee-current-phase-logo {
      position: absolute;
      left: 50%;
      top: -23px;
      height: 104px;
      width: 135px;
      margin-left: -67px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 135px 104px;
      @include background-image('facilitator/facilitator-footer-logo', 'png');
    }

    .ee-buttons-left {
      @extend .d-flex;
      @extend .flex-row;
      width: 50%;

      .ee-popup-wrapper {
        display: inline-block;

        .ee-rules-btn {
          @extend %ee-button-info;
        }
      }

      .ee-map-btn {
        @extend %ee-button;
        @extend %ee-button-icon;
        @include ee-button($teal, $teal, $white);
        margin-left: 7px;
        width: 45px;
        height: 45px;
        line-height: 1;
        padding: 10px 0;

        &:before {
          @extend .ee-icon-map;
          font-size: 1.25rem;
        }
      }
    }

    .ee-buttons-right {
      @extend .d-flex;
      @extend .flex-row;
      @extend .justify-content-end;
      width: 50%;

      .ee-settings-btn {
        @extend %ee-button-settings;
      }
    }
  }
}
