// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Basic typography
 */
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 1.125rem;
  line-height: 1.3125rem;
  color: $grey-75;
  min-width: 1024px;
}

%h1 {
  font-size: 2.25rem;
  line-height: 2.25rem;
  font-style: italic;
  text-transform: uppercase;
  text-align: center;
  color: $white;
  font-weight: 500;
  letter-spacing: -1px;
  text-shadow: 0 2px 0 $opacity-15;
}

b,
strong {
  font-weight: 700;
}
