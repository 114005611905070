.ee-facilitator-page {
  &.ee-test-game-page {
    .ee-tutorial-callout {
      @extend .d-block;

      .ee-callout {
        background: $red;
        color: $white;
        font-weight: 600;
      }
    }

    .ee-tutorial {
      position: relative;

      .ee-tutorial-tip {
        font-size: 1.125rem;
        line-height: 1.375rem;
        width: 300px;
        background: $red;
        padding: 20px 35px;
        color: $white;
        font-weight: 600;
        font-style: italic;
        position: absolute;
        text-align: center;
        border-radius: 8px;
        box-shadow: 0 4px 0 0 $opacity-15;
        z-index: 11;

        &:after {
          content: '';
          position: absolute;
          width: 68px;
          height: 40px;
          background-image: image('tutorial-tip-arrow-single.png');
          background-size: 68px 40px;
          background-repeat: no-repeat;
        }

        &-top {
          animation: floatingTooltipVertical 2s infinite ease;

          &:after {
            left: 50%;
            margin-left: -34px;
            bottom: -32px;
          }
        }

        &-right {
          animation: floatingTooltipHorizontal 2s infinite ease;

          &:after {
            transform: rotate(90deg);
            left: -12px;
            top: 50%;
            margin-top: -20px;
            margin-left: -34px;
          }
        }

        &-left {
          animation: floatingTooltipHorizontal 2s infinite ease;

          &:after {
            transform: rotate(-90deg);
            right: -47px;
            top: 50%;
            margin-top: -20px;
          }
        }

        .ee-close-btn {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 30px;
          height: 30px;

          &:before {
            @extend .ee-icon-x;
            font-size: 0.9875rem;
            line-height: 0.9875rem;
            position: absolute;
            top: 6px;
            left: 12px;
            color: $opacity-25;
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  .ee-facilitator-view {
    background-image: image('global-background.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    @include make-row(0);
  }

  .ee-phase-script-wrapper {
    @include make-col(6);
    @include background-image('facilitator/script-diagonal-lines', 'png');
    background-size: 12px 12px;
    border-right: 3px solid $warm-grey;
    position: relative;

    .ee-tutorial {
      .ee-tutorial-tip {
        &-sound,
        &-scroll {
          left: 100%;
          margin-left: -10px;
        }

        &-sound {
          top: 100px;
        }

        &-scroll {
          top: 200px;
        }
      }
    }

    .ee-phase-script-header {
      background-color: $white;
      border-bottom: 3px solid $warm-grey;
      padding: 28px 30px 16px 30px;
      position: relative;

      &:after {
        @extend .ee-icon-bolt-horizontal;
        position: absolute;
        left: 50%;
        bottom: -14px;
        margin-left: -79px;
        font-size: 10rem;
        color: $warm-grey;
        z-index: 1;
      }

      h1 {
        @extend %h1;
        font-weight: 700;
        text-shadow: 2px 2px 0 $opacity-10;
        letter-spacing: normal;
        color: inherit;
        line-height: 1;
        margin: 0;
      }
    }

    @media (max-height: 767px) {
      .ee-phase-script-header {
        padding: 14px 30px 8px 30px;
      }
    }

    .ee-scroll-pane {
      height: 580px;
      overflow-x: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }

    @media (max-height: 767px) {
      .ee-scroll-pane {
        height: 550px;
      }
    }

    .ee-phase-script-content {
      font-size: 1.5rem;
      line-height: 2.25rem;
      background-color: $tint-warmgrey-50;

      ol,
      ul,
      p {
        margin: 24px 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      ol,
      ul {
        padding-left: 15px;
      }

      .ee-phase-script-section-content {
        padding: 35px 60px;
      }
    }

    .ee-phase-script-action {
      padding: 30px 60px 45px 60px;
      position: relative;

      .ee-tutorial {
        left: 50%;
        margin-left: -150px;

        .ee-tutorial-tip {
          &-continue {
            top: -200px;
          }
        }
      }

      .ee-continue-btn {
        @extend %ee-button;
        @extend %ee-button-text;
        @include ee-button($red, $white, $white);
        width: 100%;
      }

      .ee-script-action-alert {
        position: relative;
        padding-left: 65px;
        font-style: italic;
        font-weight: 700;
        text-transform: uppercase;
        margin: 16px 0;

        &:before {
          content: '';
          position: absolute;
          top: -16px;
          left: 0;
          display: block;
          height: 50px;
          width: 50px;
          @include background-image('facilitator/icon-alert', 'png');
          background-repeat: no-repeat;
          background-size: 50px 50px;
        }
      }
    }
  }

  .ee-phase-actions-wrapper {
    @include make-col(6);
    position: relative;

    .ee-tutorial {
      .ee-tutorial-tip {
        left: 50%;

        &-top {
          top: 0;
          width: 400px;
          margin-left: -200px;
        }
      }
    }

    .ee-phase-actions-header {
      border-bottom: 3px solid darken($dark-blue, 5%);
      padding: 30px 30px 20px 30px;
      background-color: $dark-blue;

      h1 {
        @extend %h1;
        font-weight: 700;
        text-shadow: 2px 2px 0 $opacity-10;
        letter-spacing: normal;
        font-size: 1.875rem;
        line-height: 1;
        margin: 0;
      }
    }

    @media (max-height: 767px) {
      .ee-phase-actions-header {
        padding: 18px 30px 10px 30px;
      }
    }

    .ee-phase-actions-content {
      @extend .d-flex;
      @extend .flex-column;
      @extend .justify-content-center;
      height: 580px;
    }

    @media (max-height: 767px) {
      .ee-phase-actions-content {
        height: 550px;
      }
    }

    .ee-phase-actions-overview-wrapper {
      width: 385px;
      height: 385px;
      padding: 0 40px;
      margin: 0 auto;
      background-color: $dark-blue;
      color: $white;
      border-radius: 100%;
      border: 5px solid #475b70;
      box-shadow: 0 0 0 10px $dark-blue;
      position: relative;

      &:after {
        @extend .ee-icon-bolt;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 1;
        margin-top: -130px;
        width: 100%;
        text-align: center;
        font-size: 16.25rem;
        line-height: 1;
        color: lighten($dark-blue, 5%);
      }

      .ee-phase-actions-overview {
        @extend .d-flex;
        @extend .flex-column;
        @extend .justify-content-center;
        font-size: 2rem;
        line-height: 2rem;
        font-style: italic;
        font-weight: normal;
        text-align: center;
        z-index: 10;
        height: 100%;
        position: relative;
      }
    }

    .ee-phase-actions {
      @extend .d-flex;
      @extend .flex-column;
      @extend .justify-content-center;
      position: relative;
      height: 100%;
    }
  }

  .ee-phase-footer {
    @include make-row(0);
    background-color: $white;
    padding: 17px 30px;
    border-top: 3px solid #efefef;
    position: relative;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      top: -38px;
      left: 50%;
      @include background-image('facilitator/facilitator-footer-bg', 'png');
      background-size: 100%;
      width: 140px;
      height: 38px;
      margin-left: -70px;
    }

    .ee-tutorial {
      .ee-tutorial-tip {
        &-mute {
          width: 450px;
          top: -42px;
          right: 90px;
          left: auto;
        }
      }
    }

    .ee-phase-footer-logo {
      position: absolute;
      top: -20px;
      left: 50%;
      display: block;
      width: 124px;
      height: 92px;
      margin-left: -60px;
      @include background-image('facilitator/facilitator-footer-logo', 'png');
      background-size: 100%;
    }

    .ee-phase-footer-breadcrumb {
      @include make-col(6);

      ul {
        @extend .list-group;
        @extend .list-group-horizontal;

        li {
          @extend .list-group-item;
          position: relative;
          margin-right: 38px;
          line-height: 1;
          border: 0;
          padding: 0;

          &:before {
            content: '';
            height: 45px;
            width: 40px;
            font-size: 2.75rem;
            background-size: 40px 45px;
            background-repeat: no-repeat;
            display: inline-block;
          }

          &:after {
            content: '';
            position: absolute;
            right: -30px;
            top: 50%;
            display: block;
            width: 24px;
            height: 4px;
            margin-top: -4px;
            box-shadow: 0 1px 0 $grey-10 inset;
            background-color: $grey-05;
            border-radius: 2px;
          }

          &:last-child {
            margin-right: 0;

            &:after {
              display: none;
            }
          }

          &.ee-breadcrumb-complete {
            &:before {
              @extend .ee-icon-hex-check;
              position: relative;
              top: 2px;
              color: $green;
              background-image: none !important;
              text-shadow: 0 -2px 0 darken($green, 10%);
            }

            &:after {
              background-color: $green;
              box-shadow: 0 1px 0 darken($green, 10%) inset;
            }
          }

          &.ee-breadcrumb-current {
            &:after {
              margin-top: -6px;
            }
          }

          &.ee-breadcrumb-prepare {
            &:before {
              @include background-image('facilitator/facilitator-footer-icon-prepare', 'png');
            }

            &.ee-breadcrumb-current {
              &:before {
                @include background-image('facilitator/facilitator-footer-icon-prepare-current', 'png');
              }
            }
          }

          &.ee-breadcrumb-respond {
            &:before {
              @include background-image('facilitator/facilitator-footer-icon-respond', 'png');
            }

            &.ee-breadcrumb-current {
              &:before {
                @include background-image('facilitator/facilitator-footer-icon-respond-current', 'png');
              }
            }
          }

          &.ee-breadcrumb-recover {
            &:before {
              @include background-image('facilitator/facilitator-footer-icon-recover', 'png');
            }

            &.ee-breadcrumb-current {
              &:before {
                @include background-image('facilitator/facilitator-footer-icon-recover-current', 'png');
              }
            }
          }

          &.ee-breadcrumb-adapt {
            &:before {
              @include background-image('facilitator/facilitator-footer-icon-adapt', 'png');
            }

            &.ee-breadcrumb-current {
              &:before {
                @include background-image('facilitator/facilitator-footer-icon-adapt-current', 'png');
              }
            }
          }
        }
      }
    }

    .ee-phase-footer-utilities {
      @include make-col(6);
      text-align: right;

      .ee-game-settings {
        display: inline-block;
      }

      .ee-mute-btn {
        @extend %ee-button-mute;
        margin-left: 10px;
      }

      .ee-settings-btn {
        @extend %ee-button-settings;
      }
    }
  }

  .ee-phase-player-types {
    .ee-player-type-list {
      position: relative;
      height: 500px;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      @include background-image('facilitator/hexagon', 'png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 365px;

      &.ee-animate {
        opacity: 0;
        animation-name: ee-animate-bounce-in;
        animation-duration: .5s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
      }

      .ee-player-type {
        position: absolute;

        button {
          position: relative;
          z-index: 10;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 158px;
          height: 131px;

          &:disabled {
            opacity: 0.5;
          }
        }

        &.ee-animate {
          opacity: 0;
          animation-name: ee-animate-bounce-in;
          animation-duration: 0.5s;
          animation-timing-function: ease-out;
          animation-fill-mode: forwards;
        }

        &-0 {
          top: 0;
          left: 50%;
          margin-left: -79px;

          &.ee-animate {
            animation-delay: 1s;
          }
        }

        &-1 {
          top: 85px;
          right: 50%;
          margin-right: -250px;

          &.ee-animate {
            animation-delay: 1.2s;
          }
        }

        &-2 {
          bottom: 91px;
          right: 50%;
          margin-right: -250px;

          &.ee-animate {
            animation-delay: 1.4s;
          }
        }

        &-3 {
          bottom: 0;
          left: 50%;
          margin-left: -79px;

          &.ee-animate {
            animation-delay: 1.6s;
          }
        }

        &-4 {
          bottom: 91px;
          left: 50%;
          margin-left: -250px;

          &.ee-animate {
            animation-delay: 1.8s;
          }
        }

        &-5 {
          top: 85px;
          left: 50%;
          margin-left: -250px;

          &.ee-animate {
            animation-delay: 2s;
          }
        }
      }
    }

    .ee-citywide {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -30px;

      &.ee-animate {
        opacity: 0;
        animation-name: ee-animate-light-bounce-in;
        animation-duration: .5s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        animation-delay: 2.5s;
      }

      .btn {
        @extend %ee-button;
        @extend %ee-button-text-icon;
        @include ee-button($red, $white, $white);
        font-size: 0.9375rem;
        font-weight: 500;

        &:before {
          @extend .ee-icon-plus;
          @extend %ee-button-text-icon-pseudo;
          @extend %ee-button-text-icon-before;
          color: $white;
          background-color: $red;
          border-color: darken($red, 15%);
        }

        &:after {
          content: '';
          position: absolute;
          top: -39px;
          left: 50%;
          transform: translateX(-50%);
          width: 62px;
          height: 39px;
          @include background-image('facilitator/city', 'png');
          background-size: 100%;
        }
      }
    }

    .ee-notification-sent-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      text-align: center;

      .ee-notification-sent {
        display: inline-block;
        height: 8px;
        width: 8px;
        margin: 0 2px;
        border: 2px solid;
        border-radius: 6px;
      }

      .ee-notification-sent-no {
        border-width: 1px;
        border-color: $grey-35;
        background-color: $grey-75;
      }

      .ee-notification-sent-yes {
        border-color: darken($red, 20%);
        background-color: $red;
      }

      .ee-notification-sent-ack {
        border-color: darken($yellow, 20%);
        background-color: $yellow;
      }
    }
  }

  .ee-phase-dialog {
    border-radius: 5px;
    position: absolute;
    top: -50px;
    right: 45px;
    left: 45px;
    background-color: $white;

    &.ee-animate {
      opacity: 0;
      animation-name: ee-animate-light-bounce-in;
      animation-duration: .25s;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;
    }

    .ee-phase-dialog-cancel-btn {
      @extend %ee-button-close;
      position: absolute;
      top: -15px;
      right: -15px;
      z-index: 1;
    }

    .ee-phase-dialog-back-btn {
      @extend %ee-button-back;
      position: absolute;
      top: -15px;
      left: -15px;
      z-index: 1;
    }

    .ee-phase-dialog-header {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      position: relative;
      background-size: 100%;

      .ee-player-type-logo {
        position: relative;
        top: -15px;
        height: 150px;
        width: 100%;
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .ee-phase-dialog-content {
      .ee-phase-dialog-list {
        padding: 0;
        margin: 0;

        .ee-phase-dialog-list-item {
          list-style-type: none;
          position: relative;
          border-top: 2px solid $grey-05;

          &:last-child {
            .ee-phase-dialog-select-btn {
              border-bottom-right-radius: 5px;
              border-bottom-left-radius: 5px;
            }

            .ee-phase-dialog-sent-label {
              border-bottom-right-radius: 5px;
            }
          }

          .ee-phase-dialog-select-btn {
            width: 100%;
            height: 70px;
            font-size: 1.375rem;
            font-weight: 600;
            text-indent: 14px;
            letter-spacing: -1px;
            color: $teal;
            text-decoration: none;
            border-radius: 0;

            strong {
              font-weight: 800;
            }

            &:before {
              position: absolute;
              top: 12px;
              left: 19px;
              height: 44px;
              width: 44px;
              font-size: 2rem;
              line-height: 44px;
              text-indent: -1px;
              text-align: center;
              color: $white;
              background-color: $teal;
              border-radius: 22px;
            }

            &:hover {
              color: $white;
              background-color: $teal;

              &:before {
                color: $teal;
                background-color: $white;
              }
            }

            &:active {
              background-color: darken($teal, 10%);
            }

            &:disabled {
              background-color: $white;
            }

            &:hover:disabled {
              color: $teal;
              background-color: $white;
            }
          }

          .ee-phase-dialog-sent-label {
            width: 11%;
            padding-top: 28px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            font-size: 0.75rem;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;

            &-sent {
              background-color: $red;
            }

            &-ack {
              background-color: $yellow;
            }
          }
        }
      }

      .ee-phase-dialog-body {
        max-height: 340px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        border-top: 2px solid $grey-05;
        text-align: center;

        &.ee-phase-dialog-send-body {
          padding: 20px 15px 0 15px;
        }

        .ee-phase-dialog-send-title {
          @extend %ee-phase-dialog-body-title;
        }

        .ee-phase-dialog-send-description,
        .ee-phase-dialog-send-details {
          padding: 0 35px;
          margin-bottom: 15px;
          font-size: 1rem;
          line-height: 1.375rem;
        }

        .ee-phase-dialog-send-subtitle {
          font-size: 1.375rem;
          line-height: 1;
          font-weight: 700;
          font-style: italic;
          text-transform: uppercase;
          border-top: 2px solid $grey-05;
          padding: 20px 35px 0 35px;
          margin-bottom: 10px;
        }

        .ee-notification-sent-title {
          min-height: 110px;
          position: relative;
          padding-top: 25px;
          padding-right: 75px;
          padding-bottom: 10px;
          padding-left: 105px;
          text-align: left;
          font-size: 1.875rem;
          line-height: 1;
          font-style: italic;
          font-weight: 800;
          text-transform: uppercase;
          color: $white;
          border-bottom: 5px solid transparent;

          &:before {
            position: absolute;
            top: 50%;
            left: 20px;
            margin-top: -40px;
            font-size: 4.6875rem;
            line-height: 1;
          }

          &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: -5px;
            left: 0;
            height: 5px;
            background: linear-gradient(45deg, transparent 33.333%, $white 33.333%, $white 66.667%, transparent 66.667%), linear-gradient(-45deg, transparent 33.333%, $white 33.333%, $white 66.667%, transparent 66.667%);
            background-size: 10px 20px;
          }
        }

        .ee-notification-sent-subtitle {
          @extend %ee-dialog-separator;
          font-size: 1.375rem;
          line-height: 1;
          font-weight: 500;
          font-style: italic;
          text-transform: uppercase;
          padding-right: 35px;
          padding-left: 35px;
          margin-top: 25px;
          margin-right: 15px;
          margin-left: 15px;
        }

        .ee-notification-sent-message {
          padding: 0 35px;
          margin: 18px 15px;
        }
      }

      .ee-phase-dialog-footer {
        padding: 25px 30px;
        background-color: $teal;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;

        button {
          @extend %ee-button;
          @extend %ee-button-text;
          @extend %ee-button-link;
          @include ee-button(transparent, $white, $white);
          width: 100%;
          font-size: 1.25rem;
        }
      }
    }
  }

  @media (max-height: 767px) {
    .ee-phase-dialog {
      top: -28px;
    }
  }

  .ee-module-hurricane {
    .ee-phase-player-types {
      .ee-player-type-list {
        .ee-player-type {
          &-midtown {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-midtown', 'png');
            }
          }

          &-sunnybrook {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-sunnybrook', 'png');
            }
          }

          &-downtown {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-downtown', 'png');
            }
          }

          &-hillside {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-hillside', 'png');
              background-position: center;
            }
          }

          &-meadowglen {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-meadowglen', 'png');
            }
          }

          &-waterview {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-waterview', 'png');
            }
          }
        }
      }
    }

    .ee-phase-dialog-header {
      &-player-type-midtown {
        background-color: $blue;
        @include background-image('player/neighborhood-beacon-midtown-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-midtown', 'png');
        }
      }

      &-player-type-sunnybrook {
        background-color: $yellow;
        @include background-image('player/neighborhood-beacon-sunnybrook-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
        }
      }

      &-player-type-downtown {
        background-color: $purple;
        @include background-image('player/neighborhood-beacon-downtown-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-downtown', 'png');
        }
      }

      &-player-type-hillside {
        background-color: $blue;
        @include background-image('player/neighborhood-beacon-hillside-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-hillside', 'png');
        }
      }

      &-player-type-meadowglen {
        background-color: $green;
        @include background-image('player/neighborhood-beacon-meadowglen-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-meadowglen', 'png');
        }
      }

      &-player-type-waterview {
        background-color: $teal;
        @include background-image('player/neighborhood-beacon-waterview-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-waterview', 'png');
        }
      }

      &-player-type-all {
        background-color: $blue;
        @include background-image('facilitator/neighborhood-beacon-coastal-city-background', 'png');

        .ee-player-type-logo {
          @include background-image('facilitator/neighborhood-beacon-coastal-city', 'png');
        }
      }
    }
  }

  .ee-module-flood {
    .ee-phase-player-types {
      .ee-player-type-list {
        .ee-player-type {
          &-hillside {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-hillside', 'png');
            }
          }

          &-meadowglen {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-meadowglen', 'png');
            }
          }

          &-downtown {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-downtown', 'png');
            }
          }

          &-waterview {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-waterview', 'png');
            }
          }

          &-sunnybrook {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-sunnybrook', 'png');
            }
          }

          &-midtown {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-midtown', 'png');
            }
          }
        }
      }
    }


    .ee-phase-dialog-header {
      &-player-type-hillside {
        background-color: $blue;
        @include background-image('player/neighborhood-beacon-hillside-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-hillside', 'png');
        }
      }

      &-player-type-meadowglen {
        background-color: $teal;
        @include background-image('player/neighborhood-beacon-meadowglen-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-meadowglen', 'png');
        }
      }

      &-player-type-downtown {
        background-color: $purple;
        @include background-image('player/neighborhood-beacon-downtown-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-downtown', 'png');
        }
      }

      &-player-type-waterview {
        background-color: $yellow;
        @include background-image('player/neighborhood-beacon-waterview-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-waterview', 'png');
        }
      }

      &-player-type-sunnybrook {
        background-color: $green;
        @include background-image('player/neighborhood-beacon-sunnybrook-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
        }
      }

      &-player-type-midtown {
        background-color: $blue;
        @include background-image('player/neighborhood-beacon-midtown-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-midtown', 'png');
        }
      }

      &-player-type-all {
        background-color: $blue;
        @include background-image('facilitator/neighborhood-beacon-river-city-background', 'png');

        .ee-player-type-logo {
          @include background-image('facilitator/neighborhood-beacon-river-city', 'png');
        }
      }
    }
  }

  .ee-module-earthquake {
    .ee-phase-player-types {
      .ee-player-type-list {
        .ee-player-type {
          &-midtown {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-midtown', 'png');
            }
          }

          &-waterview {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-waterview', 'png');
            }
          }

          &-hillside {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-hillside', 'png');
            }
          }

          &-sunnybrook {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-sunnybrook', 'png');
            }
          }

          &-downtown {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-downtown', 'png');
            }
          }

          &-meadowglen {
            button {
              @include background-image('facilitator/phase-dialog-neighborhood-meadowglen', 'png');
            }
          }
        }
      }
    }

    .ee-phase-dialog-header {
      &-player-type-midtown {
        background-color: $green;
        @include background-image('player/neighborhood-beacon-midtown-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-midtown', 'png');
        }
      }

      &-player-type-waterview {
        background-color: $blue;
        @include background-image('player/neighborhood-beacon-waterview-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-waterview', 'png');
        }
      }

      &-player-type-hillside {
        background-color: $yellow;
        @include background-image('player/neighborhood-beacon-hillside-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-hillside', 'png');
        }
      }

      &-player-type-sunnybrook {
        background-color: $teal;
        @include background-image('player/neighborhood-beacon-sunnybrook-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
        }
      }

      &-player-type-downtown {
        background-color: $purple;
        @include background-image('player/neighborhood-beacon-downtown-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-downtown', 'png');
        }
      }

      &-player-type-meadowglen {
        background-color: $blue;
        @include background-image('player/neighborhood-beacon-meadowglen-background', 'png');

        .ee-player-type-logo {
          @include background-image('player/neighborhood-beacon-meadowglen', 'png');
        }
      }

      &-player-type-all {
        background-color: $red;
        @include background-image('facilitator/neighborhood-beacon-rock-city-background', 'png');

        .ee-player-type-logo {
          @include background-image('facilitator/neighborhood-beacon-rock-city', 'png');
        }
      }
    }
  }
}
