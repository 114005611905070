.ee-alert-banner {
  @extend .d-flex;
  @extend .flex-row;
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .flex-grow-0;
  position: fixed;
  top: 10px;
  width: 800px;
  left: 50%;
  margin-left: -400px;
  z-index: 1000;
  background: transparentize($red, .1);
  text-align: center;
  padding: 20px;

  .ee-alert {
    &-info {
      padding-left: 60px;
      position: relative;
      display: inline-block;

      &:before {
        @extend .ee-icon-exclamation-point;
        font-size: 1.875rem;
        line-height: 3.125rem;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -30px;
        width: 50px;
        height: 50px;
        background: $pink;
        color: $white;
        border-radius: 100px;
      }
    }

    header {
      h1 {
        @extend %h1;
        font-size: 1.875rem;
        line-height: 2rem;
        font-weight: 800;
        letter-spacing: normal;
        position: relative;
        top: 5px;
        text-shadow: none;
      }
    }

    p {
      color: $white;
      margin: 5px 0 0 0;
    }
  }

  .btn {
    @extend %ee-button;
    @extend %ee-button-text;
    @include ee-button($red, $white, $white);
  }
}

.ee-home-view {
  @extend .flex-grow-1;

  header {
    h1 {
      @extend %h1;
      margin-bottom: 20px;
    }
  }

  .ee-game-options {
    @extend .d-flex;
    @extend .flex-row;
    padding: 0 30px;

    .ee-game-option {
      @extend %ee-box;
      width: 48%;
      margin-right: 1%;
      margin-left: 1%;
      box-shadow: none;
      min-height: 300px;
      padding: 30px;
      text-align: center;

      .ee-option-title {
        font-size: 1.6875rem;
        line-height: 1;
        color: $dark-blue;
        font-style: italic;
        font-weight: 700;
        text-align: center;
        margin: 0 0 10px 0;
        position: relative;
        display: inline-block;

        &:before {
          content: '';
          position: absolute;
          left: -80px;
          top: 50%;
          font-size: 3.75rem;
          line-height: 3.75rem;
        }

        &-start {
          &:before {
            background-image: image('portal-start-group.png');
            background-size: 67px 42px;
            width: 67px;
            height: 42px;
            margin-top: -25px;
          }
        }

        &-test {
          &:before {
            background-image: image('portal-test-group.png');
            background-size: 53px 48px;
            width: 53px;
            height: 48px;
            margin-top: -28px;
          }
        }
      }

      .ee-divider {
        width: 100%;
        height: 20px;
        margin-bottom: 30px;
        background-image: image('facilitator/horizontal-lightning-bolt.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
      }

      .ee-option-action {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 30px;

        .btn {
          @extend %ee-button;
          @extend %ee-button-text;
          width: 300px;

          &.ee-start-btn {
            @include ee-button($teal, $white, $white);
          }

          &.ee-test-btn {
            @include ee-button($white, $teal, $teal);
          }
        }
      }
    }
  }
}

.ee-thankyou-view {
  .ee-header {
    @extend .d-block;
    padding-top: 40px;
    height: 350px;
    margin-bottom: 150px;
    position: relative;

    h1 {
      @extend %h1;
      margin: 0;

      strong {
        display: block;
        font-size: 3.5rem;
        line-height: 3.75rem;
        font-weight: 800;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 120px;
      left: 0;
      right: 0;
      bottom: -120px;
      @include background-image('public-header-bottom', 'png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .ee-logo {
      margin-left: -200px;

      a {
        top: 0;
        left: 0;
        width: 390px;
        height: 320px;
      }
    }
  }

  .ee-thankyou {
    width: 400px;
    margin: 0 auto 45px auto;
    text-align: center;
    color: $white;

    h2 {
      margin-bottom: 25px;
      font-size: 1.875rem;
      line-height: 1;
      font-weight: 500;
      font-style: italic;
    }
  }

  .ee-footer {
    position: static;
  }
}

.ee-admin-view {

  .ee-admin-menu {

    text-align: center;

    .btn {
      @extend %ee-button;
      @extend %ee-button-text;
      //width: 300px;
      margin-top: 1rem;

      &.ee-admin-btn {
        @include ee-button($teal, $teal, $white);
      }
    }

  }
}

.ee-game-data {

  .ee-when {

    margin-top: 1rem;
    margin-bottom: 1rem;

    .btn {
      @extend %ee-button;
      @extend %ee-button-text;
      //width: 300px;
      margin-right: 0.5rem;

      &.ee-when-btn {
        @include ee-button(transparent, $teal, $teal);
      }

      &.ee-when-btn-active {
        @include ee-button($teal, $teal, $white);
      }

      &.ee-when-btn-download {
        @include ee-button(transparent, $teal, $white);
        margin-right: 0;
      }
    }

    .react-datepicker-wrapper {
      display: inline-block;
    }

    .react-datepicker__tab-loop {
      display: inline-block;
    }
  }

}