@charset "UTF-8";

@import '~include-media/dist/include-media';

@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

@import '~bootstrap/scss/bootstrap';
@import '~react-tabs/style/react-tabs';
@import '~react-toastify/scss/main';

@import 'base/base';
@import 'base/fonts';
@import 'base/animations';

@import 'components/alert';
@import 'components/button';
@import 'components/custom-elements';
@import 'components/footer';
@import 'components/form';
@import 'components/header';
@import 'components/phase-dialog';
@import 'components/popup';
@import 'components/toast';
@import 'components/tooltip';

@import 'layout/container';

@import 'themes/mixins';

@import 'pages/account';
@import 'pages/game';
@import 'pages/game/facilitator/start-game';
@import 'pages/game/facilitator/lobby';
@import 'pages/game/facilitator/game-phase';
@import 'pages/game/facilitator/game-phase-prepare';
@import 'pages/game/facilitator/game-phase-respond';
@import 'pages/game/facilitator/game-phase-recover';
@import 'pages/game/facilitator/game-phase-adapt';
@import 'pages/game/player/start-game';
@import 'pages/game/player/lobby';
@import 'pages/game/player/game-phase';
@import 'pages/game/player/game-phase-prepare';
@import 'pages/game/player/game-phase-respond';
@import 'pages/game/player/game-phase-recover';
@import 'pages/game/player/game-phase-adapt';
