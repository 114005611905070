.ee-player-page {
  .ee-game-play-tip-modal {
    .ee-game-play-modal-background {
      overflow: hidden;

      .ee-lines-1 {
        @include background-image('player/game-play-modal-light-teal-lines', 'png');
      }

      .ee-lines-2 {
        @include background-image('player/game-play-modal-dark-teal-lines', 'png');
      }
    }

    .ee-popup-title {
      background-color: $tint-teal-20;

      h1 {
        @extend .d-flex;
        @extend .flex-column;
        @extend .justify-content-center;
        font-size: 4.6875rem;
        line-height: 4.6875rem;
        height: 150px;
        padding-left: 175px;

        &:before {
          @extend .ee-icon-tip;
          top: -10px;
          left: 0;
          font-size: 9.6875rem;
          color: $white;
        }
      }
    }

    .ee-popup-content-icon {
      &:before {
        @include background-image('info-box-icon-intro', 'png');
      }
    }

    .ee-popup-content-divider {
      background-color: $teal;
    }

    .ee-popup-actions {
      background-color: $shade-teal-2;
    }
  }

  .ee-player-prepare-view {
    @include background-image('player/beacon-texture', 'png');

    .ee-player-type-overview {
      .ee-player-type-information {
        @extend %ee-box;
        padding: 20px 30px;
        margin-bottom: 35px;

        .ee-actions {
          padding-bottom: 22px;
          border-bottom: 2px solid $grey-10;

          .ee-resources-btn {
            @extend %ee-button-info;
            width: 100%;
            max-width: 300px;
          }

          .react-tabs__tab-panel {
            text-align: left;
          }
        }

        > ul {
          list-style-type: none;
          padding: 0;
          margin-bottom: 15px;
          font-size: 1.375rem;
          line-height: 1.75rem;

          li {
            padding: 10px 0;
            border-bottom: 2px solid $grey-10;
          }
        }
      }
    }

    .ee-player-content {
      background-color: $opacity-50;
      padding: 35px;
    }

    .ee-player-aside {
      padding: 35px;

      .ee-player-type {
        height: 225px;
        margin-top: 0;
        margin-bottom: 15px;
      }
    }

    .ee-splash-view {
      .ee-player-type-overview {
        .ee-player-type-information {
          @extend %ee-info-box;
          padding-right: 0;
          padding-left: 0;

          ul {
            font-size: 1.25rem;
            line-height: 1.375rem;
            margin: 0;

            li {
              padding: 15px 75px;

              &:last-child {
                border: 0;
              }
            }
          }
        }
      }
    }

    .ee-city-overview {
      width: 100%;

      .ee-city-data-section {
        padding-bottom: 50px;

        header {
          @extend %ee-header-with-bolt;
          position: relative;
          padding-bottom: 5px;
          margin-bottom: 20px;
          border-bottom-width: 2px;
          border-bottom-style: solid;

          &:after {
            font-size: 8.75rem;
            bottom: -71px;
          }

          h2 {
            margin: 0;
            color: $yellow;
            font-size: 1rem;
            line-height: 1;
            font-weight: 700;
            text-transform: uppercase;
          }

          h3 {
            margin: 2px 0 0 0;
            font-size: 0.875rem;
            line-height: 1;
            font-weight: 700;
          }
        }
      }

      .ee-city-data-population {
        .ee-city-data-value {
          @extend %ee-city-data-bold;
          font-size: 8rem;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 430px;
            height: 180px;
            @include background-image('player/hurricane/phase-prepare-population', 'png');
            background-repeat: repeat-x;
            background-size: 8px 90px;
            background-position: top right;
          }
        }

        .ee-city-datum-growth {
          font-size: 0.875rem;
          text-align: right;

          .ee-city-datum-label {
            &:before {
              @extend .ee-icon-i;
              text-align: center;
              font-size: 1rem;
              line-height: 1.5rem;
              width: 30px;
              height: 30px;
              margin-right: 8px;
              background-color: lighten($teal, 10%);
              border: 3px solid $teal;
              border-radius: 100px;
            }
          }
        }
      }

      .ee-city-data-weather {
        @include make-row();

        .ee-city-datum {
          @include make-col-ready();
          @include make-col(6);
          @extend .d-flex;
          min-height: 84px;
          margin-bottom: 25px;
        }

        .ee-city-datum-label {
          @extend %ee-city-data-label;
          width: 90px;
          vertical-align: top;
        }

        .ee-city-datum-value {
          @extend %ee-city-data-bold;
          min-width: 175px;
          padding-right: 75px;
          font-size: 5.25rem;
          text-align: right;
          background-repeat: no-repeat;
          background-position: right top;

          .ee-city-datum-value-letter {
            &:last-child {
              font-size: 1.875rem;
              vertical-align: text-top;
            }
          }
        }

        .ee-city-datum-asterisk {
          @extend %ee-city-data-label;
          font-style: normal;
          text-transform: none;
          margin-top: -10px;
          position: absolute;
          bottom: 0;
        }

        .ee-city-datum-summer-temperature {
          .ee-city-datum-value {
            @include background-image('player/hurricane/phase-prepare-weather-summer', 'png');
            background-size: 65px 64px;
          }
        }

        .ee-city-datum-winter-temperature {
          .ee-city-datum-value {
            @include background-image('player/hurricane/phase-prepare-weather-winter', 'png');
            background-size: 50px 50px;
          }
        }

        .ee-city-datum-rainfall {
          .ee-city-datum-value {
            @include background-image('player/hurricane/phase-prepare-weather-rainfall', 'png');
            background-size: 59px 50px;
          }
        }

        .ee-city-datum-snowfall {
          .ee-city-datum-value {
            @include background-image('player/hurricane/phase-prepare-weather-snowfall', 'png');
            background-size: 59px 46px;
          }
        }
      }

      .ee-city-data-highlights {
        @extend .d-flex;
        @extend .justify-content-between;

        .ee-city-datum {
          display: inline-block;
          width: 128px;
          height: 128px;
          padding-top: 76px;
          font-size: 0.875rem;
          line-height: 1rem;
          font-weight: 500;
          text-align: center;
          background-size: 100%;
        }
      }
    }

    &-local-and-state-government {
      background-color: $teal;

      .ee-player-type {
        @include background-image('player/sector-beacon-local-and-state-government', 'png');
      }

      .ee-city-data-section {
        header {
          border-bottom-color: $shade-teal-40;

          &:after {
            color: $shade-teal-40;
          }
        }

        .ee-city-datum-moderate-crime,
        .ee-city-datum-high-crime {
          color: $shade-teal-40;
        }
      }

      .ee-popup-tabbed {
        header {
          background-color: $teal;

          .ee-popup-header {
            @include background-image('player/sector-beacon-local-and-state-government', 'png');
          }
        }
      }
    }

    &-businesses {
      background-color: $red;

      .ee-player-type {
        @include background-image('player/sector-beacon-businesses', 'png');
      }

      .ee-city-data-section {
        header {
          border-bottom-color: $shade-red-40;

          &:after {
            color: $shade-red-40;
          }
        }

        .ee-city-datum-moderate-crime,
        .ee-city-datum-high-crime {
          color: $shade-red-40;
        }
      }

      .ee-popup-tabbed {
        header {
          background-color: $red;

          .ee-popup-header {
            @include background-image('player/sector-beacon-businesses', 'png');
          }
        }
      }
    }

    &-community-groups {
      background-color: $purple;

      .ee-player-type {
        @include background-image('player/sector-beacon-community-groups', 'png');
      }

      .ee-city-data-section {
        header {
          border-bottom-color: $shade-purple-40;

          &:after {
            color: $shade-purple-40;
          }
        }

        .ee-city-datum-moderate-crime,
        .ee-city-datum-high-crime {
          color: $shade-purple-40;
        }
      }

      .ee-popup-tabbed {
        header {
          background-color: $purple;

          .ee-popup-header {
            @include background-image('player/sector-beacon-community-groups', 'png');
          }
        }
      }
    }

    &-households {
      background-color: $yellow;

      .ee-player-type {
        @include background-image('player/sector-beacon-households', 'png');
      }

      .ee-city-data-section {
        header {
          border-bottom-color: $shade-yellow-40;

          &:after {
            color: $shade-yellow-40;
          }
        }

        .ee-city-datum-moderate-crime,
        .ee-city-datum-high-crime {
          color: $shade-yellow-40;
        }
      }

      .ee-popup-tabbed {
        header {
          background-color: $yellow;

          .ee-popup-header {
            @include background-image('player/sector-beacon-households', 'png');
          }
        }
      }
    }

    &-federal-government {
      background-color: $blue;

      .ee-player-type {
        @include background-image('player/sector-beacon-federal-government', 'png');
      }

      .ee-city-data-section {
        header {
          border-bottom-color: $shade-blue-40;

          &:after {
            color: $shade-blue-40;
          }
        }

        .ee-city-datum-moderate-crime,
        .ee-city-datum-high-crime {
          color: $shade-blue-40;
        }
      }

      .ee-popup-tabbed {
        header {
          background-color: $blue;

          .ee-popup-header {
            @include background-image('player/sector-beacon-federal-government', 'png');
          }
        }
      }
    }

    &-first-responders {
      background-color: $green;

      .ee-player-type {
        @include background-image('player/sector-beacon-first-responders', 'png');
      }

      .ee-city-data-section {
        header {
          border-bottom-color: $shade-green-40;

          &:after {
            color: $shade-green-40;
          }
        }

        .ee-city-datum-moderate-crime,
        .ee-city-datum-high-crime {
          color: $shade-green-40;
        }
      }

      .ee-popup-tabbed {
        header {
          background-color: $green;

          .ee-popup-header {
            @include background-image('player/sector-beacon-first-responders', 'png');
          }
        }
      }
    }
  }

  .ee-module-hurricane {
    .ee-player-prepare-view {
      &-local-and-state-government {
        .ee-city-datum-moderate-crime,
        .ee-city-datum-high-crime {
          color: $shade-teal-40;
        }
      }

      &-businesses {
        .ee-city-datum-moderate-crime,
        .ee-city-datum-high-crime {
          color: $shade-red-40;
        }
      }

      &-community-groups {
        .ee-city-datum-moderate-crime,
        .ee-city-datum-high-crime {
          color: $shade-purple-40;
        }
      }

      &-households {
        .ee-city-datum-moderate-crime,
        .ee-city-datum-high-crime {
          color: $shade-yellow-40;
        }
      }

      &-federal-government {
        .ee-city-datum-moderate-crime,
        .ee-city-datum-high-crime {
          color: $shade-blue-40;
        }
      }

      &-first-responders {
        .ee-city-datum-moderate-crime,
        .ee-city-datum-high-crime {
          color: $shade-green-40;
        }
      }
    }

    .ee-city-data-population {
      @extend %ee-city-data-bold;
      font-size: 8rem;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 430px;
        height: 180px;
        @include background-image('player/hurricane/phase-prepare-population', 'png');
        background-repeat: repeat-x;
        background-size: 8px 90px;
        background-position: top right;
      }
    }

    .ee-city-data-distance {
      @extend %ee-city-data-bold;
      padding-top: 60px;
      margin-top: -30px;
      text-align: center;
      font-size: 6.25rem;
      @include background-image('player/hurricane/phase-prepare-distance', 'png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 557px 110px;
    }

    .ee-city-data-demographics {
      display: table;
      width: 100%;

      .ee-city-datum {
        display: table-cell;

        .ee-city-datum-label {
          @extend %ee-city-data-label;
        }

        .ee-city-datum-value {
          padding-top: 50px;
          font-size: 2.75rem;
          line-height: 1;
          font-weight: 700;
          background-position: top left;
          background-repeat: repeat-x;
          background-size: 19px 37px;
        }

        &-age-group-1 {
          width: 20%;

          .ee-city-datum-value {
            @include background-image('player/hurricane/phase-prepare-demographics-group-1', 'png');
          }
        }

        &-age-group-2 {
          width: 58%;

          .ee-city-datum-value {
            @include background-image('player/hurricane/phase-prepare-demographics-group-2', 'png');
          }
        }

        &-age-group-3 {
          width: 22%;

          .ee-city-datum-value {
            @include background-image('player/hurricane/phase-prepare-demographics-group-3', 'png');
          }
        }
      }
    }

    .ee-city-data-crime-rate {
      display: table;
      width: 100%;
      margin-bottom: 75px;

      .ee-city-datum {
        display: table-cell;
        width: 33%;
        border-bottom-width: 20px;
        border-bottom-style: solid;
        text-align: center;

        .ee-city-datum-label {
          text-transform: uppercase;
          line-height: 1;
          font-size: 1.5rem;
        }

        .ee-city-datum-value {
          @extend .sr-only;
        }

        &-low-crime {
          border-bottom-color: $green;

          .ee-city-datum-label {
            font-size: 2rem;
            font-weight: 700;
            position: relative;
            margin-bottom: 5px;

            &:before {
              content: '';
              position: absolute;
              z-index: 1;
              bottom: -12px;
              left: 50%;
              margin-left: -7px;
              height: 10px;
              width: 10px;
              transform: rotate(45deg);
              background-color: $green;
            }

            &:after {
              content: '';
              position: absolute;
              z-index: 10;
              bottom: -20px;
              left: 50%;
              margin-left: -7px;
              height: 10px;
              width: 10px;
              background-color: $white;
              border-radius: 10px;
            }
          }
        }

        &-moderate-crime {
          border-bottom-color: $yellow;

        }

        &-high-crime {
          border-bottom-color: $red;
        }
      }
    }

    .ee-city-data-income {
      @extend %ee-city-data-bold;
      letter-spacing: -3px;
      font-size: 3.5rem;
      padding-bottom: 40px;
      margin-bottom: 60px;
      @include background-image('player/hurricane/phase-prepare-income', 'png');
      background-position: center left;
      background-repeat: no-repeat;
      background-size: 578px 53px;
    }

    .ee-city-data-area {
      @extend .clearfix;

      .ee-city-datum {
        width: 50%;
      }

      .ee-city-datum-total-area {
        @extend .float-left;

        .ee-city-datum-label {
          @extend .sr-only;
        }

        .ee-city-datum-value {
          @extend %ee-city-data-bold;
          height: 250px;
          width: 250px;
          font-size: 7.8125rem;
          line-height: 18.125rem;
          text-align: center;
          @include background-image('player/hurricane/phase-prepare-area', 'png');
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .ee-city-datum-water-area,
      .ee-city-datum-land-area {
        @extend .float-right;
        position: relative;

        .ee-city-datum-label {
          @extend %ee-city-data-label;
          position: absolute;
          top: 20px;
          right: 50px;
          width: 100px;
        }

        .ee-city-datum-value {
          right: 200px;
          width: 125px;
          font-size: 5.125rem;
          line-height: 1;
          font-weight: 700;
          text-align: center;
          background-repeat: no-repeat;
        }
      }

      .ee-city-datum-water-area {
        margin-top: 45px;

        .ee-city-datum-value {
          @include background-image('player/hurricane/phase-prepare-area-water', 'png');
          background-position: center 20px;
          background-size: 109px 19px;
        }
      }

      .ee-city-datum-land-area {
        .ee-city-datum-value {
          @include background-image('player/hurricane/phase-prepare-area-land', 'png');
          background-position: center 27px;
          background-size: 111px 11px;
        }
      }
    }
  }

  .ee-module-flood {
    .ee-city-overview {
      .ee-city-data {
        .ee-city-datum-asterisk {
          position: relative;
          padding-left: 40px;
          margin-top: -5px;
          font-size: 0.875rem;
        }
      }

      .ee-city-data-highlights {
        .ee-city-datum {
          &-farmland {
            @include background-image('player/flood/demographics-farmland', 'png');
          }

          &-history {
            @include background-image('player/flood/demographics-history', 'png');
          }

          &-beauty {
            @include background-image('player/flood/demographics-beauty', 'png');
          }

          &-education {
            @include background-image('player/flood/demographics-education', 'png');
          }
        }
      }

      .ee-city-data-population {
        .ee-city-data-value {
          font-size: 6.75rem;

          &:after {
            left: 440px;
          }
        }
      }

      .ee-city-data-weather {
        .ee-city-datum {
          margin-bottom: 35px;
        }

        .ee-city-datum-label {
          @extend .order-1;
        }

        .ee-city-datum-value {
          @extend .order-0;
          margin-right: 10px;
        }

        .ee-city-datum-asterisk {
          position: absolute;
          top: 75px;
        }
      }

      .ee-city-data-jobs {
        position: relative;
        width: 576px;
        height: 219px;
        @include background-image('player/flood/demographics-jobs', 'png');
        background-repeat: no-repeat;
        background-size: 100%;

        .ee-city-datum {
          position: absolute;
          text-align: right;
          text-transform: uppercase;
          font-weight: 600;
          font-style: italic;
          font-size: 0.75rem;
          line-height: 0.875rem;
        }

        .ee-city-datum-agriculture {
          top: 63px;
          left: 162px;
        }

        .ee-city-datum-retail {
          top: 106px;
          left: 262px;
        }

        .ee-city-datum-research {
          top: 136px;
          left: 318px;
        }

        .ee-city-datum-construction {
          top: 180px;
          left: 412px;
        }

        .ee-city-datum-health-care {
          top: 209px;
          left: 458px;
        }
      }

      .ee-city-data-income {
        position: relative;
        margin-top: 30px;
        width: 576px;
        height: 157px;
        @include background-image('player/flood/demographics-income', 'png');
        background-size: 100%;

        .ee-city-datum {
          position: absolute;
          font-size: 0.75rem;
          line-height: 0.875rem;
          font-style: italic;
          text-transform: uppercase;
        }

        .ee-city-datum-income-river-city {
          top: 5px;
          left: 190px;
        }

        .ee-city-datum-income-national {
          top: 125px;
          left: 358px;
        }
      }
    }
  }

  .ee-module-earthquake {
    .ee-city-overview {
      .ee-city-data-highlights {
        .ee-city-datum {
          &-stunning-vistas {
            @include background-image('player/earthquake/demographic-stunning-vistas', 'png');
          }

          &-boundless-opportunity {
            @include background-image('player/earthquake/demographic-boundless-opportunity', 'png');
          }

          &-celebrating-culture {
            @include background-image('player/earthquake/demographic-celebrating-culture', 'png');
          }

          &-treasuring-history {
            @include background-image('player/earthquake/demographic-treasuring-history', 'png');
          }
        }
      }

      .ee-city-data-population {
        .ee-city-data-value {
          height: 220px;

          &:after {
            top: 120px;
            right: 0;
            left: 0;
          }
        }
      }

      .ee-city-data-income {
        @extend .clearfix;

        .ee-city-data-value {
          @extend %ee-city-data-bold;
          @extend .float-left;
          font-size: 5.75rem;
          letter-spacing: -3px;
          @include background-image('player/earthquake/phase-prepare-income-household', 'png');
          background-position: center right;
          background-repeat: no-repeat;
          background-size: 175px 156px;
          width: 30%;
          height: 156px;
          padding-top: 45px;
          padding-left: 8px;
        }

        .ee-city-datum {
          @extend .float-right;
          width: 50%;
          position: relative;
          margin-bottom: 10px;
          padding-top: 5px;
          padding-bottom: 5px;
          text-align: left;

          &:before {
            content: '';
            position: absolute;
            background-image: image('player/earthquake/dot-pattern@2x.png');
            background-repeat: repeat-x;
            height: 5px;
            width: 100%;
            left: 0;
            top: -10px;
          }

          &:after {
            content: '';
            position: absolute;
            left: -60px;
            top: 3px;
            width: 45px;
            height: 18px;
            background-repeat: no-repeat;
            background-size: 45px 18px;
          }
        }

        .ee-city-datum-cost-living {
          margin-top: 40px;

          &:after {
            background-image: image('player/earthquake/phase-prepare-income-5.png');
          }
        }

        .ee-city-datum-housing-shortages {
          &:after {
            background-image: image('player/earthquake/phase-prepare-income-3.png');
          }
        }

        .ee-city-datum-low-unemployment {
          &:after {
            background-image: image('player/earthquake/phase-prepare-income-1.png');
          }
        }
      }

      .ee-city-data-daily-life {
        height: 275px;
        width: 100%;
        background-image: image('player/earthquake/phase-prepare-rock-city-daily-life-infographic.png');
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 640px 274px;
      }

      .ee-city-data-weather {
        .ee-city-datum {
          @include make-col(4);
          @extend .d-flex;
          @extend .flex-column;

          .ee-city-datum-label {
            @extend .order-1;
          }

          .ee-city-datum-value {
            @extend .order-0;
            text-align: left;
            background-position: 100px top;
          }

          .ee-city-datum-asterisk {
            @extend .order-2;
            position: static;
            margin-top: 10px;
          }
        }
      }

      .ee-city-data-environment {
        @include make-row();

        .ee-city-datum {
          @include make-col-ready();
          @include make-col(6);

          .ee-city-datum-value {
            font-size: 5.75rem;
            line-height: 1;
            font-weight: 700;
            letter-spacing: -1px;
            display: inline-block;
          }

          .ee-city-datum-label {
            font-size: 0.75rem;
            line-height: 0.9375rem;
            position: absolute;
            right: 20px;
            top: 0;
            width: 170px;
            font-style: italic;
            text-transform: uppercase;
          }

          .ee-city-datum-asterisk {
            font-weight: 700;
            display: inline-block;

            &:before {
              content: '';
              display: inline-block;
              position: relative;
              top: 7px;
              background-image: image('player/earthquake/phase-prepare-alert-asterisk.png');
              background-size: 100%;
              background-repeat: no-repeat;
              width: 27px;
              height: 25px;
              margin-right: 5px;
            }
          }
        }

        .ee-city-data-image {
          width: 100%;
          height: 175px;
          background-image: image('player/earthquake/phase-prepare-environment-infographic.png');
          background-repeat: no-repeat;
          background-position: right bottom;
          background-size: 500px 175px;
          margin-top: 20px;
        }
      }

      .ee-city-data-employment-sectors {
        height: 275px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          right: -35px;
          bottom: -185px;
          width: 100%;
          height: 178px;
          background-image: image('player/earthquake/prepare-rock-city-illustration-footer.png');
          background-size: 533px 178px;
          background-repeat: no-repeat;
          background-position: bottom right;
        }

        .ee-city-datum {
          font-weight: 700;
          text-transform: uppercase;
          font-style: italic;
          padding: 15px 15px 10px 15px;
        }

        .ee-city-datum-tech {
          background: #79b4be;
          width: 90%;
        }

        .ee-city-datum-healthcare {
          background: #ffd675;
          width: 75%;
        }

        .ee-city-datum-tourism {
          background: #e0495c;
          width: 50%;
        }

        .ee-city-datum-education {
          background: #88c249;
          width: 40%;
        }

        .ee-city-datum-mining {
          background: #c173a3;
          width: 30%;
        }
      }
    }
  }
}
