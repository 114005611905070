%ee-alert-box {
  padding-top: 40px;
  background-color: $white;
  border-top-width: 5px;
  border-top-style: solid;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 3px 0 0 $opacity-20;
  color: $grey-70;
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    top: -29px;
    right: 0;
    left: 0;
    display: block;
    height: 54px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 49px 54px;
  }
}

%ee-warn-box {
  @extend %ee-alert-box;
  border-top-color: $yellow;

  &:after {
    @include background-image('info-box-icon', 'png');
  }
}

%ee-info-box {
  @extend %ee-alert-box;
  border-top-color: $teal;

  &:after {
    @include background-image('info-box-icon-intro', 'png');
  }
}

%ee-box {
  @extend %ee-alert-box;
  border: 0;

  &:after {
    background-image: none;
  }
}
