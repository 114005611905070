%ee-header {
  @extend .d-flex;
  @extend .flex-row;
  @extend .justify-content-between;
  @extend .flex-shrink-0;
  background-image: image('public-black-transparent-bg.png');
  height: 80px;
  color: $white;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 25px;

  &:after {
    content: '';
    position: absolute;
    display: block;
    @include background-image('public-header-bottom', 'png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .ee-logo {
    a {
      @include background-image('logo', 'png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 995;
      display: block;
    }
  }

  .ee-header-actions {
    .btn {
      @extend %ee-button-with-text;
      margin-top: 10px;
    }
  }
}

.ee-header {
  @extend %ee-header;
  margin-bottom: 140px;

  &:after {
    left: 0;
    right: 0;
    bottom: -120px;
    height: 120px;
    background-position: center;
  }

  .ee-logo {
    position: absolute;
    left: 50%;
    margin-left: -150px;

    a {
      position: relative;
      height: 155px;
      width: 216px;
      top: 18px;
      left: 40px;
    }
  }

  .ee-header-actions {

    z-index: 997;

    .ee-account-btn {
      max-width: 400px;
      text-overflow: clip;
      overflow: hidden;
      white-space: nowrap;

      @include ee-button-with-text(darken($dark-blue, 20%), $faded-blue, $yellow);

      &:before {
        @extend .ee-icon-user;
      }
    }

    .ee-admin-btn {
      @include ee-button-with-text(darken($dark-blue, 20%), $faded-blue, $yellow);

      &:before {
        @extend .ee-icon-gear;
      }
    }

    .ee-logout-btn {
      @include ee-button-with-text($red, $white, $white);

      &:before {
        @extend .ee-icon-logout;
      }
    }

    .ee-login-btn,
    .ee-create-account-btn {
      margin-top: 25px;
    }
  }
}

.ee-alternate-header {
  @extend %ee-header;
  margin-bottom: 80px;

  &:after {
    height: 60px;
    left: 0;
    right: 0;
    bottom: -60px;
    background-position: 30px 0;
  }

  .ee-logo {
    margin-top: 10px;
    margin-left: 19px;
    width: 216px;
    height: 115px;

    a {
      position: absolute;
      top: 10px;
      left: 34px;
      width: 216px;
      height: 115px;
    }
  }

  .ee-title {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;

    h1 {
      @extend %h1;
      font-weight: 800;
      letter-spacing: normal;
      margin-top: 30px;
    }
  }

  .ee-header-actions {
    z-index: 2;

    .ee-cancel-btn {
      @include ee-button-with-text($red, $white, $white);

      &:before {
        @extend .ee-icon-x;
        padding-left: 26px;
      }
    }
  }
}
