.ee-facilitator-page {
  .ee-phase-player-types {
    .ee-player-type-list-prepare {
      .ee-player-type {
        button {
          width: 154px;
          height: 124px;
        }

        &-0 {
          top: 0;
          left: 50%;
          margin-left: -76px;

          button {
            @include background-image('facilitator/phase-dialog-sector-local-and-state-government', 'png');
          }
        }

        &-1 {
          top: 90px;
          right: 25px;
          margin-right: 0;

          button {
            @include background-image('facilitator/phase-dialog-sector-businesses', 'png');
          }
        }

        &-2 {
          bottom: 85px;
          right: 25px;
          margin-right: 0;

          button {
            @include background-image('facilitator/phase-dialog-sector-community-groups', 'png');
          }
        }

        &-3 {
          bottom: 0;
          left: 50%;
          margin-left: -76px;

          button {
            @include background-image('facilitator/phase-dialog-sector-households', 'png');
          }
        }

        &-4 {
          bottom: 85px;
          left: 25px;
          margin-left: 0;

          button {
            @include background-image('facilitator/phase-dialog-sector-federal-government', 'png');
          }
        }

        &-5 {
          top: 90px;
          left: 25px;
          margin-left: 0;

          button {
            @include background-image('facilitator/phase-dialog-sector-first-responders', 'png');
          }
        }
      }
    }

    .ee-citywide-prepare {
      .btn {
        margin-left: -83px;
      }
    }
  }

  .ee-phase-dialog-prepare {
    .ee-phase-dialog-header {
      &-player-type-0 {
        background-color: $teal;

        .ee-player-type-logo {
          @include background-image('player/sector-beacon-local-and-state-government', 'png');
        }
      }

      &-player-type-1 {
        background-color: $red;

        .ee-player-type-logo {
          @include background-image('player/sector-beacon-businesses', 'png');
        }
      }

      &-player-type-2 {
        background-color: $purple;

        .ee-player-type-logo {
          @include background-image('player/sector-beacon-community-groups', 'png');
        }
      }

      &-player-type-3 {
        background-color: $yellow;

        .ee-player-type-logo {
          @include background-image('player/sector-beacon-households', 'png');
        }
      }

      &-player-type-4 {
        background-color: $blue;

        .ee-player-type-logo {
          @include background-image('player/sector-beacon-federal-government', 'png');
        }
      }

      &-player-type-5 {
        background-color: $green;

        .ee-player-type-logo {
          @include background-image('player/sector-beacon-first-responders', 'png');
        }
      }
    }

    .ee-phase-dialog-content {
      .ee-phase-dialog-list {
        .ee-phase-dialog-list-item {
          .ee-phase-dialog-select-btn {
            &:before {
              @extend .ee-icon-tip;
              padding-left: 4px;
            }
          }
        }
      }

      .ee-phase-dialog-body {
        .ee-notification-sent-title {
          background-color: $green;

          &:before {
            @extend .ee-icon-tip;
            color: darken($green, 10%);
          }
        }
      }
    }
  }
}
