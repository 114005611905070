.ee-player-page {
  .ee-player-adapt-view {
    .ee-player-footer {
      .ee-buttons-left {
        .btn {
          display: none;
        }
      }
    }

    .ee-player-game-wrapper {
      background-image: image('global-background.jpg');
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .ee-scroll-pane {
      width: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }

    .ee-goals-view {
      @extend .d-flex;
      @extend .flex-column;
      @extend .justify-content-center;
      width: 900px;
      margin: 0 auto;

      .ee-goals-header {
        @extend .flex-shrink-0;
        @include background-image('player/adapt-disaster-title', 'png');
        background-size: 100%;
        width: 244px;
        height: 130px;
        margin: 30px auto;
      }

      .ee-goals {
        @extend %ee-alert-box;
        padding: 45px 65px;
        margin-bottom: 150px;
        border-top-color: $purple;

        &:after {
          @include background-image('player/adapt-key-learnings-badge', 'png');
          background-size: 100%;
          background-repeat: no-repeat;
          width: 46px;
          height: 52px;
          top: -28px;
          left: 50%;
          margin-left: -23px;
        }

        .ee-goals-title {
          padding-bottom: 35px;
          border-bottom: 2px solid $grey-10;
          position: relative;
          font-size: 1.875rem;
          line-height: 2rem;
          font-weight: 800;
          font-style: italic;
          text-transform: uppercase;
          color: #3f4c59;

          &:after {
            @extend .ee-icon-bolt-horizontal;
            position: absolute;
            left: 50%;
            bottom: -16px;
            margin-left: -79px;
            font-size: 10rem;
            color: $grey-10;
          }
        }

        .ee-goals-list {
          padding: 0;
          margin: 0;
          list-style-type: none;

          li {
            @extend .d-flex;
            @extend .flex-row;
            text-align: left;
            border-bottom: 2px solid $grey-10;
            padding: 50px 0;

            .ee-goal-number {
              @extend .flex-shrink-0;
              width: 66px;
              height: 66px;
              padding-top: 5px;
              font-size: 2.75rem;
              line-height: 66px;
              font-weight: 800;
              text-align: center;
              color: $grey-20;
              box-shadow: inset 0 2px $grey-20;
              background-color: $grey-05;
              border-radius: 100px;
            }

            .ee-goal-text {
              padding-left: 30px;
              margin-top: 8px;
              font-size: 1.375rem;
              line-height: 1.75rem;
            }
          }
        }
      }
    }
  }
}
