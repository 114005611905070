.ee-popup {
  text-align: center;
  border-radius: 5px;
  margin: 0 auto;
}

.ee-popup-close-btn {
  @extend %ee-button;
  @extend %ee-button-icon;
  @extend %ee-button-link;
  @include ee-button($red, $white, $white);
  padding-top: 11px;
  padding-left: 24px;
  position: absolute;
  z-index: 2;

  &:before {
    @extend .ee-icon-x;
  }
}

.ee-popup-wrapper {
  .popup-overlay {
    background-color: $opacity-80 !important;
    z-index: 100 !important;
  }

  .popup-content {
    width: auto !important;
    border-width: 0 !important;
    padding: 0 !important;
    background-color: transparent !important;
    z-index: 101 !important;
  }

  .ee-popup {
    width: 600px;
    color: $grey-75;
    background-color: $white;

    .ee-popup-close-btn {
      top: -13px;
      right: -13px;

      &:before {
        left: 13px;
      }
    }

    .ee-popup-title {
      font-size: 1.875rem;
      line-height: 2rem;
      font-weight: 700;
      font-style: italic;
      border-bottom: 2px solid $warm-grey;
      position: relative;

      &:after {
        @extend .ee-icon-bolt-horizontal;
        font-size: 10rem;
        color: $warm-grey;
        position: absolute;
        left: 50%;
        bottom: -17px;
        margin-left: -79px;
      }
    }

    .ee-popup-footer {
      border-top: 2px solid $warm-grey;
      margin-top: 30px;
      padding-top: 20px;
    }

    .ee-popup-actions {
      padding: 8px 95px;
      border-radius: 0 0 4px 4px;

      &-info {
        background-color: $teal;
      }

      &-alert {
        background-color: $red;
      }
    }

    &-confirm {
      .ee-popup-title {
        padding: 20px;
        margin: 0 32px 20px 32px;
      }

      .ee-popup-content {
        margin: 0 32px;
        padding: 4px 40px 24px 40px;
      }

      .ee-popup-actions {
        .btn {
          @extend %ee-button;
          @extend %ee-button-icon;
          @extend %ee-button-link;
          width: 100%;
        }

        &-info {
          .btn {
            @include ee-button($teal, $white, $white);
          }
        }

        &-alert {
          .btn {
            @include ee-button($red, $white, $white);
          }
        }
      }
    }

    &-news {
      padding: 50px 65px 30px 65px;

      .ee-popup-title {
        font-weight: 800;
        text-transform: uppercase;
        padding-bottom: 30px;
        margin-bottom: 30px;
      }

      .ee-popup-content {
        font-size: 1.375rem;
        line-height: 1.625rem;
      }

      .ee-popup-footer {
        dl {
          text-align: center;
          font-size: 0.75rem;
          text-transform: uppercase;
          margin: 0;

          dt {
            display: inline-block;
            margin-right: 5px;
          }

          dd {
            display: inline-block;

            &.ee-news-source {
              @extend %ee-news-source;
            }
          }
        }
      }
    }

    &-tabbed {
      header {
        height: 95px;
        @include background-image('player/participant-modal-header', 'png');
        background-size: 100%;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;

        .ee-popup-header {
          position: relative;
          top: -15px;
          height: 100px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;

          span {
            position: absolute;
            top: 54px;
            left: 380px;
            width: 100px;
            font-size: 1rem;
            line-height: 1.125rem;
            font-style: italic;
            text-transform: uppercase;
            color: $white;
            font-weight: 700;
            text-align: left;
          }
        }
      }

      .react-tabs__tab-list {
        @extend .d-flex;
        margin: 0;
        border: 0;
        background-color: $grey-10;

        .react-tabs__tab {
          @extend .flex-fill;
          border: 0;
          border-right: 2px solid $white;
          border-radius: 0;
          white-space: nowrap;
          padding: 5px 5px 0 5px;
          font-size: 1rem;
          line-height: 2.8125rem;
          font-weight: 700;
          font-style: italic;
          text-transform: uppercase;
          color: $teal;

          &:last-child {
            border: 0;
          }

          &:hover {
            background-color: $grey-05;
          }

          &--selected {
            background-color: $white;
            color: darken($teal, 10%);

            &:hover {
              background-color: $white;
            }
          }
        }
      }

      .react-tabs__tab-panel {
        height: 375px;
        padding: 35px 65px;
        font-size: 1.375rem;
        line-height: 1.875rem;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      }
    }

    &-map {
      width: 914px;
      height: 705px;
      background-size: 100%;
    }

    @media (max-height: 767px) {
      &-map {
        width: 778px;
        height: 600px;
      }
    }
  }
}

.ee-game-play-modal {
  .popup-overlay {
    background-color: transparent !important;
  }

  .popup-content {
    width: 770px !important;
    border-width: 0 !important;
    padding: 0 !important;
    background-color: transparent !important;
  }

  .ee-game-play-modal-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 500;

    .ee-lines {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 300%;
      transform: translate(-50%, -50%) rotate(45deg);

      .ee-lines-1,
      .ee-lines-2 {
        height: 100%;
        background-size: 280px;
        transform: translateY(0);
      }
    }
  }

  .ee-popup-close-btn {
    z-index: 1000;
    top: 35px;
    right: 35px;
  }

  .ee-popup-title-wrapper,
  .ee-popup-content-wrapper,
  .ee-popup-actions-wrapper {
    overflow: hidden;
  }

  .ee-popup-title {
    @extend .d-flex;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 35px 25px;

    h1 {
      @extend %h1;
      font-weight: 400;
      letter-spacing: -2px;
      text-shadow: none;
      position: relative;

      strong {
        font-weight: 800;
      }

      &:before {
        position: absolute;
        line-height: 1;
      }
    }
  }

  .ee-popup-content-icon {
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      top: -25px;
      right: 0;
      left: 0;
      display: block;
      height: 54px;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 49px 54px;
    }
  }

  .ee-popup-content-divider {
    height: 5px;
    margin: 0 auto;
    width: 100%;
  }

  .ee-popup-content {
    padding: 70px 75px 25px 75px;
    font-size: 1.375rem;
    line-height: 1.625rem;
    background-color: $white;
  }

  .ee-popup-actions {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 10px 60px;

    button {
      @extend %ee-button;
      @extend %ee-button-text;
      @extend %ee-button-link;
      @include ee-button(transparent, $white, $white);
      width: 100%;
      display: block;
    }
  }
}

.ee-module-flood {
  .ee-popup-wrapper {
    .ee-popup {
      &-map {
        @include background-image('player/flood/river-city-map', 'jpg');
      }
    }
  }
}
