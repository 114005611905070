%ee-callout {
  margin-left: 0;
  margin-right: 0;
  padding: 20px 30px;
  font-size: 1.1875rem;
  line-height: 1.875rem;
  font-style: italic;
  border-radius: 5px;
  background-color: $warm-grey;
}

.ee-callout {
  @extend %ee-callout;
}

.ee-callout-with-banner {
  @extend %ee-callout;
  position: relative;
  margin-top: 30px;

  .ee-callout-banner {
    width: 100%;
    height: 25px;
    position: absolute;
    top: -13px;
    left: 0;
    line-height: 29px;
    text-align: center;
    @include background-image('facilitator/sound-effect-banner', 'png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    .ee-callout-banner-label {
      position: relative;
      display: inline-block;
      padding-left: 35px;
      font-size: 0.9375rem;
      font-weight: 700;
      font-style: italic;
      text-transform: uppercase;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: -3px;
        display: block;
        width: 30px;
        height: 30px;
        @include background-image('facilitator/icon-sound-effect', 'png');
        background-size: 30px 30px;
        background-repeat: no-repeat;
      }
    }
  }
}

.ee-sound-effect {
  .ee-callout-banner {
    @include background-image('facilitator/sound-effect-banner', 'png');

    .ee-callout-banner-label {
      &:before {
        @include background-image('facilitator/icon-sound-effect', 'png');
      }
    }
  }

  button {
    @extend %ee-button;
    @extend %ee-button-text;
    @extend %ee-button-link;
    @include ee-button($red, $white, $white);
    width: 100%;

    &:after {
      @extend .ee-icon-speaker-full;
      font-size: 1.25rem;
      line-height: 1.25rem;
      margin-top: -5px;
      margin-left: 10px;
    }
  }
}

.ee-timing-tip {
  .ee-callout-banner {
    @include background-image('facilitator/timing-tip-banner', 'png');

    &:before {
      @include background-image('facilitator/icon-timing-tip', 'png');
    }
  }
}

.ee-phase-complete {
  position: relative;
  padding-left: 50px;
  margin-top: 24px;
  font-weight: 700;
  font-style: italic;

  &:before {
    @extend .ee-icon-check;
    position: absolute;
    top: -5px;
    left: 0;
    width: 40px;
    height: 40px;
    font-size: 1.25rem;
    text-align: center;
    color: $white;
    background-color: $green;
    border: 3px solid $white;
    border-radius: 20px;
  }
}
