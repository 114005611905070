// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

@mixin background-image($path, $extension) {
  background-image: image($path + '.' + $extension);

  @include media('retina2x') {
    background-image: image($path + '@2x.' + $extension);
  }
}
