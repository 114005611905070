.ee-facilitator-page {
  .ee-phase-player-types {
    .ee-player-type-list-recover {
      top: -25px;

      .ee-player-type {
        .ee-neighborhood-score {
          position: absolute;
          left: 50%;
          width: 60px;
          padding-top: 25px;
          margin-top: 35px;
          margin-left: -30px;

          &:before,
          &:after {
            @extend .ee-icon-banner;
            position: absolute;
            line-height: 1;
          }

          &:before {
            top: 5px;
            left: -2px;
            z-index: 2;
            font-size: 4.0625rem;
          }

          &:after {
            top: 0;
            left: -8px;
            z-index: 1;
            font-size: 4.8125rem;
          }

          &-value {
            position: relative;
            z-index: 10;
            display: block;
            font-size: 0.5rem;
            line-height: 1;
            font-weight: 400;
            font-style: italic;
            text-align: center;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.7);

            strong {
              display: block;
              font-size: 1.5rem;
              font-weight: 800;
              font-style: normal;
              letter-spacing: -1px;
              color: $white;
            }
          }
        }
      }
    }

    .ee-citywide-recover {
      width: 200px;
      margin-top: -60px;
      margin-left: -100px;
      text-align: center;
      font-size: 0.625rem;
      line-height: 1;
      font-weight: 400;
      font-style: italic;
      color: $white;
      text-transform: uppercase;

      &:before {
        content: '';
        display: block;
        width: 81px;
        height: 51px;
        @include background-image('citywide-score', 'png');
        background-size: 81px 51px;
        margin-left: auto;
        margin-right: auto;
      }

      strong {
        position: relative;
        top: 8px;
        display: block;
        font-size: 3.25rem;
        font-style: normal;
        font-weight: 800;
        letter-spacing: -1px;
      }
    }
  }

  .ee-phase-dialog-recover {
    .ee-phase-dialog-title {
      padding-top: 16px;
      padding-right: 60px;
      padding-left: 60px;
      margin-bottom: 10px;
      font-size: 1.375rem;
      line-height: 1.5625rem;
      font-style: italic;
      text-transform: uppercase;
      font-weight: 700;
    }

    .ee-decisions {
      @extend .d-flex;
      @extend .flex-row;
      padding: 0 5px 10px 5px;

      .ee-decision {
        @extend %ee-button-link;
        min-height: 120px;
        width: 48%;
        margin-right: 1%;
        margin-left: 1%;
        padding: 70px 20px 20px 20px;
        border: 3px solid lighten($teal, 10%);
        border-radius: 3px;
        color: $teal;
        font-size: 1.25rem;
        line-height: inherit;
        font-weight: 600;
        text-decoration: none;
        position: relative;

        &:before {
          top: 25px;
          left: 10px;
        }

        &:after {
          position: absolute;
          top: 10px;
          left: 0;
          width: 100%;
          color: $teal;
          line-height: 1;
          text-align: center;
          font-size: 3.125rem;
        }

        &:hover {
          color: $white;
          background-color: lighten($teal, 10%);

          &:before,
          &:after {
            color: $white;
          }
        }

        &-selected {
          background: $green;
          color: $white;
          border-color: $green;

          &:before {
            @extend .ee-icon-check;
            font-size: 1.25rem;
            line-height: 1.25rem;
            position: absolute;
            z-index: 1;
            top: 30px;
            left: 50%;
            margin-left: -10px;
            color: $white;
          }

          &:after {
            color: lighten($green, 10%);
          }

          &:hover {
            background: $green;

            &:after {
              color: lighten($green, 10%);
            }
          }
        }
      }
    }

    .ee-completed-needs {
      padding: 10px 55px;

      .ee-completed {
        @extend %ee-button-link;
        position: relative;
        display: inline-block;
        width: 48%;
        margin-right: 1%;
        margin-left: 1%;
        margin-bottom: 4px;
        padding: 16px 0 12px 0;
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 800;
        text-align: center;
        color: $teal;
        border: 3px solid lighten($teal, 10%);
        border-radius: 5px;
        text-decoration: none;

        &:hover {
          background-color: lighten($teal, 10%);
          color: $white;
        }

        &-selected {
          border-color: $green;
          color: lighten($green, 10%);
          background: $green;

          &:before {
            @extend .ee-icon-check;
            font-size: 1.25rem;
            line-height: 1.25rem;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            margin-top: -10px;
            margin-left: -10px;
            color: $white;
          }

          &:hover {
            background: $green;
            color: lighten($green, 10%);
          }
        }
      }
    }

    .ee-phase-dialog-body {
      &-done {
        padding: 20px 15px 0 15px;

        hr {
          border-top: 2px solid $grey-05;
        }

        .ee-phase-dialog-title {
          padding: 0 0 23px 0;
          margin-bottom: 25px;
          border-bottom: 2px solid $grey-05;
          font-size: 1.375rem;
          line-height: 1;
          position: relative;

          &:after {
            @extend .ee-icon-bolt-horizontal;
            position: absolute;
            z-index: 1;
            left: 0;
            bottom: 62px;
            height: 16px;
            font-size: 10rem;
            line-height: 1;
            color: $grey-05;
            width: 100%;
          }
        }

        .ee-phase-dialog-subtitle {
          margin: 20px 0;
          font-size: 0.875rem;
          line-height: 1;
          font-style: italic;
          font-weight: 700;
          text-transform: uppercase;
        }

        .ee-decision {
          position: relative;
          padding-right: 90px;
          padding-left: 10px;
          margin-top: 20px;
          margin-bottom: 20px;
          font-size: 1.375rem;
          line-height: 1.5rem;
          font-weight: 600;
          color: $green;
          text-align: left;

          &:after {
            font-size: 4.375rem;
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -12px;
          }
        }

        .ee-score {
          font-weight: 800;
          font-size: 6rem;
          line-height: 1;
          color: $green;
        }
      }
    }
  }

  .ee-module-hurricane {
    .ee-phase-player-types {
      .ee-player-type-list-recover {
        .ee-player-type {
          &-midtown {
            .ee-neighborhood-score {
              top: 70px;

              &:before {
                color: darken($green, 10%);
              }

              &:after {
                color: $green;
              }
            }
          }

          &-sunnybrook {
            .ee-neighborhood-score {
              top: 67px;

              &:before {
                color: darken($teal, 10%);
              }

              &:after {
                color: $teal;
              }
            }
          }

          &-downtown {
            .ee-neighborhood-score {
              top: 69px;

              &:before {
                color: darken($purple, 10%);
              }

              &:after {
                color: $purple;
              }
            }
          }

          &-hillside {
            .ee-neighborhood-score {
              top: 69px;

              &:before {
                color: darken($yellow, 10%);
              }

              &:after {
                color: $yellow;
              }
            }
          }

          &-meadowglen {
            .ee-neighborhood-score {
              top: 77px;

              &:before {
                color: darken($blue, 10%);
              }

              &:after {
                color: $blue;
              }
            }
          }

          &-waterview {
            .ee-neighborhood-score {
              top: 69px;

              &:before {
                color: darken($blue, 10%);
              }

              &:after {
                color: $blue;
              }
            }
          }
        }
      }
    }

    .ee-phase-dialog-recover {
      &-midtown {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-challenge-move;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-challenge-supplies;
            }
          }
        }
      }

      &-sunnybrook {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-challenge-move;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-challenge-supplies;
            }
          }
        }
      }

      &-downtown {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-challenge-family;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-challenge-shelter;
            }
          }
        }
      }

      &-hillside {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-challenge-route;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-challenge-shelter;
            }
          }
        }
      }

      &-meadowglen {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-challenge-supplies;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-challenge-move-supplies;
              font-size: 4.375rem;
            }
          }
        }
      }

      &-waterview {
        .ee-decision {
          &-0,
          &-1 {
            &:after {
              @extend .ee-icon-challenge-flood;
            }
          }
        }
      }
    }
  }

  .ee-module-flood {
    .ee-phase-player-types {
      .ee-player-type-list-recover {
        .ee-player-type {
          &-hillside {
            .ee-neighborhood-score {
              top: 76px;

              &:before {
                color: darken($yellow, 10%);
              }

              &:after {
                color: $yellow;
              }
            }
          }

          &-meadowglen {
            .ee-neighborhood-score {
              top: 74px;

              &:before {
                color: darken($blue, 10%);
              }

              &:after {
                color: $blue;
              }
            }
          }

          &-downtown {
            .ee-neighborhood-score {
              top: 69px;

              &:before {
                color: darken($purple, 10%);
              }

              &:after {
                color: $purple;
              }
            }
          }

          &-waterview {
            .ee-neighborhood-score {
              top: 69px;

              &:before {
                color: darken($blue, 10%);
              }

              &:after {
                color: $blue;
              }
            }
          }

          &-sunnybrook {
            .ee-neighborhood-score {
              top: 72px;

              &:before {
                color: darken($teal, 10%);
              }

              &:after {
                color: $teal;
              }
            }
          }

          &-midtown {
            .ee-neighborhood-score {
              top: 70px;

              &:before {
                color: darken($green, 10%);
              }

              &:after {
                color: $green;
              }
            }
          }
        }
      }
    }

    .ee-phase-dialog-recover {
      &-hillside {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-challenge-shelter;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-challenge-move-supplies;
              font-size: 4.375rem;
            }
          }
        }
      }

      &-meadowglen {
        .ee-decision {
          &-0,
          &-1 {
            &:after {
              @extend .ee-icon-challenge-flood;
            }
          }
        }
      }

      &-downtown {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-hardhat;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-tools;
              font-size: 2.5rem;
            }
          }
        }
      }

      &-waterview {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-run;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-tools;
              font-size: 2.5rem;
            }
          }
        }
      }

      &-sunnybrook {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-run;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-challenge-shelter;
            }
          }
        }
      }

      &-midtown {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-challenge-move-supplies;
              font-size: 4.375rem;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-water-block;
              font-size: 5rem;
            }
          }
        }
      }
    }
  }

  .ee-module-earthquake {
    .ee-phase-player-types {
      .ee-player-type-list-recover {
        .ee-player-type {
          &-midtown {
            .ee-neighborhood-score {
              top: 70px;

              &:before {
                color: darken($green, 10%);
              }

              &:after {
                color: $green;
              }
            }
          }

          &-waterview {
            .ee-neighborhood-score {
              top: 77px;

              &:before {
                color: darken($blue, 10%);
              }

              &:after {
                color: $blue;
              }
            }
          }

          &-hillside {
            .ee-neighborhood-score {
              top: 80px;

              &:before {
                color: darken($yellow, 10%);
              }

              &:after {
                color: $yellow;
              }
            }
          }

          &-sunnybrook {
            .ee-neighborhood-score {
              top: 69px;

              &:before {
                color: darken($teal, 10%);
              }

              &:after {
                color: $teal;
              }
            }
          }

          &-downtown {
            .ee-neighborhood-score {
              top: 69px;

              &:before {
                color: darken($purple, 10%);
              }

              &:after {
                color: $purple;
              }
            }
          }

          &-meadowglen {
            .ee-neighborhood-score {
              top: 77px;

              &:before {
                color: darken($blue, 10%);
              }

              &:after {
                color: $blue;
              }
            }
          }
        }
      }
    }

    .ee-phase-dialog-recover {
      &-midtown {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-challenge-shelter;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-challenge-caregivers;
            }
          }
        }
      }

      &-waterview {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-challenge-flood;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-challenge-flood;
            }
          }
        }
      }

      &-hillside {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-hardhat;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-challenge-row;
            }
          }
        }
      }

      &-sunnybrook {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-medical-relief;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-run;
            }
          }
        }
      }

      &-downtown {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-fire;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-medical-relief;
            }
          }
        }
      }

      &-meadowglen {
        .ee-decision {
          &-0 {
            &:after {
              @extend .ee-icon-challenge-shelter;
            }
          }

          &-1 {
            &:after {
              @extend .ee-icon-challenge-route;
            }
          }
        }
      }
    }
  }
}
