// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Bootstrap overrides

$font-family-sans-serif: ('BrandonGrotesque');

// Colors

$blue:             #3475ae !default;
$dark-blue:        #425466 !default;
$green:            #8bc059 !default;
$purple:           #a05480 !default;
$red:              #de4b5e !default;
$pink:             #f26576 !default;
$teal:             #78b6c0 !default;
$yellow:           #ffd676 !default;
$dark-yellow:      #e3bb5d !default;
$warm-grey:        #dddbd5 !default;
$orange:           #f5ba55 !default;
$faded-blue:       #3d4b5c !default;
$opaque-dark-blue: #5a6c7c !default;

$white:   #ffffff !default;
$black:   #000000 !default;

$grey-05: mix($black, $white, 5%)  !default; // #f2f2f2
$grey-10: mix($black, $white, 10%) !default; // #e6e6e6
$grey-15: mix($black, $white, 15%) !default; // #d9d9d9
$grey-20: mix($black, $white, 20%) !default; // #cccccc
$grey-25: mix($black, $white, 25%) !default; // #bfbfbf
$grey-30: mix($black, $white, 30%) !default; // #b3b3b3
$grey-35: mix($black, $white, 35%) !default; // #a6a6a6
$grey-40: mix($black, $white, 40%) !default; // #999999
$grey-45: mix($black, $white, 45%) !default; // #8c8c8c
$grey-50: mix($black, $white, 50%) !default; // #808080
$grey-60: mix($black, $white, 60%) !default; // #666666
$grey-70: mix($black, $white, 70%) !default; // #4d4d4d
$grey-75: mix($black, $white, 75%) !default; // #404040
$grey-80: mix($black, $white, 80%) !default; // #333333
$grey-85: mix($black, $white, 85%) !default; // #262626
$grey-90: mix($black, $white, 90%) !default; // #191919
$grey-95: mix($black, $white, 95%) !default; // #0d0d0d

$opacity-10: rgba(0, 0, 0, .1) !default;
$opacity-15: rgba(0, 0, 0, .15) !default;
$opacity-20: rgba(0, 0, 0, .2) !default;
$opacity-25: rgba(0, 0, 0, .25) !default;
$opacity-30: rgba(0, 0, 0, .3) !default;
$opacity-40: rgba(0, 0, 0, .4) !default;
$opacity-50: rgba(0, 0, 0, .5) !default;
$opacity-60: rgba(0, 0, 0, .6) !default;
$opacity-70: rgba(0, 0, 0, .7) !default;
$opacity-80: rgba(0, 0, 0, .8) !default;
$opacity-85: rgba(0, 0, 0, .85) !default;
$opacity-90: rgba(0, 0, 0, .9) !default;

$tint-teal-10: mix($white, $teal, 10%) !default;
$tint-teal-20: mix($white, $teal, 20%) !default;
$tint-warmgrey-50: mix($white, $warm-grey, 50%) !default;

$shade-teal-2: mix($black, $teal, 2%) !default;
$shade-teal-20: mix($black, $teal, 20%) !default;
$shade-teal-40: mix($black, $teal, 40%) !default;
$shade-red-8: mix($black, $red, 8%) !default;
$shade-red-10: mix($black, $red, 10%) !default;
$shade-red-15: mix($black, $red, 15%) !default;
$shade-red-40: mix($black, $red, 40%) !default;
$shade-purple-40: mix($black, $purple, 40%) !default;
$shade-yellow-15: mix($black, $yellow, 15%) !default;
$shade-yellow-40: mix($black, $yellow, 40%) !default;
$shade-yellow-50: mix($black, $yellow, 50%) !default;
$shade-blue-40: mix($black, $blue, 40%) !default;
$shade-green-10: mix($black, $green, 10%) !default;
$shade-green-40: mix($black, $green, 40%) !default;

// Container max width and height
$max-width:  1024px !default;
$max-height:  747px !default;

/// Relative or absolute URL where all assets are served from
/// @type String
$base-url: '../../assets/' !default;


