.ee-player-page {
  .ee-game-play-challenge-modal {
    .ee-game-play-modal-background {
      .ee-lines-1 {
        @include background-image('player/game-play-modal-light-red-lines', 'png');
      }

      .ee-lines-2 {
        @include background-image('player/game-play-modal-dark-red-lines', 'png');
      }
    }

    .ee-popup-title {
      @extend .flex-column;
      @extend .justify-content-center;
      background-color: #e65165;

      h1 {
        font-size: 4.25rem;
        line-height: 4.0625rem;
        padding-left: 150px;
        margin-top: 10px;
        margin-bottom: 10px;

        &:before {
          top: 50%;
          left: 0;
          font-size: 8.125rem;
          color: $shade-red-8;
          margin-top: -65px;
          width: 130px;
          text-align: center;
        }
      }
    }

    .ee-popup-content-icon {
      &:before {
        @include background-image('info-box-icon', 'png');
      }
    }

    .ee-popup-content-divider {
      background-color: $shade-yellow-15;
    }

    .ee-popup-actions {
      background-color: $shade-yellow-15;
    }
  }

  .ee-player-respond-view {
    .ee-event-animation {
      position: absolute;
      top: 0;
      z-index: 999;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .ee-event-animation-vignette {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.8;
        @include background-image('player/neighborhood-beacon-vignette', 'png');
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
      }

      .ee-event-animation-rain {
        position: absolute;
        width: 100%;
        height: 100%;
        @include background-image('player/neighborhood-beacon-rain', 'png');
        background-size: 200%;
        opacity: 0.4;
      }

      .ee-event-animation-lightning {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: rgba(255, 255, 255, 0.3);

        &-1 {
          @include background-image('player/neighborhood-beacon-lightning-a', 'png');
          background-position: top left;
        }

        &-2 {
          @include background-image('player/neighborhood-beacon-lightning-b', 'png');
          background-position: top right;
        }
      }
    }

    .ee-splash-view {
      width: 920px;
    }

    .ee-challenge-notification-wrapper {
      padding: 15px 60px;
      color: $white;
      background: linear-gradient(-45deg, $red 25%, $shade-red-10 25%, $shade-red-10 50%, $red 50%, $red 75%, $shade-red-10 75%);
      background-size: 80px 80px;
      border-bottom: 5px solid transparent;
      z-index: 1;

      .ee-challenge-notification {
        @extend .d-flex;
        @extend .flex-row;
        padding: 20px 20px 20px 80px;
        background-color: $red;
        position: relative;

        &:before {
          position: absolute;
          top: 38px;
          left: 0;
          width: 80px;
          font-size: 4.375rem;
          text-align: center;
          color: $shade-red-10;
        }

        .ee-notification-details {
          header {
            h1 {
              padding-top: 7px;
              margin: 0;
              font-size: 3rem;
              line-height: 1;
              font-style: italic;
              text-transform: uppercase;
              font-kerning: normal;
              letter-spacing: -2px;
              font-weight: 700;

              strong {
                font-weight: 800;
              }
            }
          }

          section {
            position: relative;
            top: -3px;
            font-size: 0.875rem;
            line-height: 1;
            font-weight: 800;
          }
        }

        .ee-notification-timer {
          margin-left: auto;
          text-align: center;

          .ee-notification-timer-value {
            line-height: 3.375rem;
            white-space: nowrap;

            .ee-char {
              display: inline-block;
              text-align: center;
              font-size: 3rem;
              line-height: 2.5rem;
              width: 40px;
              margin: 0 1px;
              background-color: $shade-red-10;
              border-top: 2px solid $shade-red-15;
              padding: 9px 5px 0 5px;
              border-radius: 2px;

              &-0 {
                display: none;
              }

              &-2 {
                position: relative;
                top: -5px;
                left: -2px;
                width: 5px;
                font-size: 2.25rem;
                font-weight: 300;
                border: 0;
                background-color: transparent;
                padding: 0;
              }
            }
          }

          .ee-notification-timer-label {
            font-size: 0.6875rem;
            line-height: 1;
            font-style: italic;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
      }
    }

    .ee-blackout {
      position: absolute;
      top: 0;
      bottom: -95px;
      left: 0;
      right: 0;
      background-color: $opacity-85;
      z-index: 10;
    }

    .ee-player-content {
      padding: 25px;
    }

    .ee-player-type-overview {
      .ee-neighborhood-welcome {
        padding-top: 8px;
        margin-top: 0;
        font-size: 1.375rem;
        line-height: 3.4375rem;
        text-transform: uppercase;
        font-style: italic;
        text-align: center;
        @include background-image('player/neighborhood-beacon-banner', 'png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 903px 55px;
        color: $white;
      }

      .ee-player-type {
        height: 300px;
      }

      .ee-player-type-information {
        @extend %ee-warn-box;
        padding: 35px 75px 25px 75px;
        font-size: 1.375rem;
        line-height: 1.625rem;
      }
    }

    .ee-challenge-board {
      width: 100%;

      .ee-challenge-board-overview {
        @extend %ee-box;
        font-size: 1.375rem;
        line-height: 1.5rem;
        margin-top: 65px;
        margin-bottom: 45px;
        padding-top: 0;

        header {
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;

          h2 {
            position: relative;
            top: -40px;
            height: 135px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        section {
          padding: 30px 70px 20px 70px;
        }
      }

      .ee-challenge-board-paths {
        @extend .d-flex;
        @extend .flex-row;
        margin-bottom: 125px;

        .ee-board-path {
          width: 50%;

          &-0 {
            margin-right: 5px;

            header {
              border-top-color: lighten($yellow, 10%);

              &:before {
                @include background-image('player/option-a', 'png');
              }
            }

            .ee-needs-title {
              color: $yellow;
            }
          }

          &-1 {
            margin-left: 5px;

            header {
              border-top-color: lighten($teal, 10%);

              &:before {
                @include background-image('player/option-b', 'png');
              }
            }

            .ee-needs-title {
              color: $teal;
            }
          }

          .ee-needs-title {
            &-complete {
              color: $green;
            }
          }

          header {
            color: $grey-70;
            border-radius: 5px;
            background-color: $white;
            box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.4);
            position: relative;
            padding: 30px 30px 10px 30px;
            text-align: center;
            border-top-width: 4px;
            border-top-style: solid;

            &:hover {
              cursor: pointer;
            }

            &:before {
              content: '';
              position: absolute;
              top: -27px;
              left: 0;
              right: 0;
              height: 52px;
              width: 100%;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }

            h3 {
              padding-bottom: 95px;
              margin-top: 20px;
              margin-bottom: 0;
              font-size: 1.25rem;

              span {
                opacity: 0.2;
              }

              &:after {
                font-size: 6.25rem;
                line-height: 95px;
                color: $grey-10;
                position: absolute;
                right: 0;
                left: 0;
                bottom: 5px;
              }
            }
          }

          .ee-needs-header {
            position: relative;
            z-index: 1;
            padding-top: 10px;
            padding-bottom: 30px;
            margin-top: 0;
            margin-bottom: 10px;
            font-size: 0.6875rem;
            line-height: 1.3125rem;
            font-style: italic;
            text-align: center;
            text-transform: uppercase;
            color: $red;
            @include background-image('player/phase-respond-challenge-arrow', 'png');
            background-position: top center;
            background-repeat: no-repeat;
            background-size: 168px 55px;
          }

          .ee-needs-wrapper {
            opacity: 0.2;
            margin-bottom: 40px;

            .ee-needs-title {
              position: relative;
              padding-bottom: 5px;
              margin: 0;
              font-size: 1.125rem;
              text-align: center;
              text-transform: uppercase;
              font-weight: 700;
            }

            .ee-needs-list {
              @extend .d-flex;
              @extend .flex-column;
              @extend .align-items-center;
              list-style-type: none;
              margin: 0;
              padding: 0;

              .ee-needs-list-item {
                @extend .d-flex;
                @extend .align-items-center;
                @extend .justify-content-center;
                position: relative;
                z-index: 1;
                border-radius: 5px;
                margin-top: -4px;
                min-height: 70px;
                padding: 15px 50px;
                width: 100%;
                text-align: center;
                font-weight: 800;
                border: 4px solid $dark-blue;

                &:before {
                  content: '';
                  position: absolute;
                  left: 5px;
                  top: 50%;
                  margin-top: -25px;
                  background-image: image('player/respond-card-default.png');
                  background-repeat: no-repeat;
                  background-size: 34px 50px;
                  width: 34px;
                  height: 50px;
                }

                &:hover {
                  cursor: pointer;
                }

                &-selected {
                  color: $green;

                  &:before {
                    background-image: image('player/respond-card-acquired.png');
                  }
                }

                &-disabled {
                  color: $red;

                  &:before {
                    background-image: image('player/respond-card-disabled.png');
                  }
                }
              }

              &-single {
                .ee-needs-list-item {
                  &-selected {
                    border-color: $green;
                  }

                  &-disabled {
                    border-color: $red;
                  }
                }
              }

              &-multiple {
                .ee-needs-list-item {
                  border-bottom: 0;

                  &:not(:first-child) {
                    border-top-style: dotted;

                    &:after {
                      content: 'and';
                      position: absolute;
                      z-index: 1;
                      top: 0;
                      left: 50%;
                      margin-left: -17px;
                      margin-top: -19px;
                      font-size: 0.6875rem;
                      line-height: 2.125rem;
                      font-weight: 800;
                      text-transform: uppercase;
                      text-align: center;
                      color: $white;
                      background-color: $dark-blue;
                      width: 34px;
                      height: 34px;
                      border-radius: 100px;
                      padding-top: 2px;
                    }
                  }

                  &:last-child {
                    border-bottom: 4px solid $dark-blue;
                  }

                  &-selected {
                    border-color: $green;

                    & + .ee-needs-list-item {
                      border-top-color: $green;
                    }

                    & + .ee-needs-list-item-selected {
                      &:after {
                        background-color: $green;
                      }
                    }

                    &:last-child {
                      border-color: $green;
                    }
                  }

                  &-disabled {
                    border-color: $red;

                    & + .ee-needs-list-item {
                      border-top-color: $red;
                    }

                    &:not(:first-child) {
                      &:after {
                        background-color: $red;
                      }
                    }

                    &:last-child {
                      border-color: $red;
                    }
                  }
                }
              }
            }

            .ee-needs-separator {
              position: relative;
              display: inline-block;
              width: 100%;
              height: 30px;
              padding-top: 6px;
              margin: 10px 0;
              font-size: 0.6875rem;
              font-weight: 800;
              text-transform: uppercase;
              text-align: center;
              z-index: 1;

              &:before {
                content: '';
                width: 100%;
                height: 4px;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -2px;
                z-index: -1;
                background-color: #313f4e;
              }

              &:after {
                content: '';
                width: 26px;
                height: 26px;
                background-color: $dark-blue;
                transform: rotate(45deg);
                position: absolute;
                left: 50%;
                margin-left: -13px;
                top: 50%;
                margin-top: -13px;
                z-index: -1;
              }
            }
          }

          &-selected {
            header {
              h3 {
                span {
                  opacity: 1;
                }
              }
            }

            .ee-needs-wrapper {
              opacity: 1;
            }
          }

          &-0-selected {
            header {
              border-top-color: $yellow;
            }
          }

          &-1-selected {
            header {
              border-top-color: $teal;
            }
          }
        }
      }
    }

    .ee-event-tracker {
      position: relative;
      height: 255px;
      min-height: 255px;
      padding: 25px;
      overflow: hidden;
      background-size: 100%;
      z-index: 1;

      .ee-event-tracker-pulse-center {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
        border-radius: 100px;
        background-color: $white;

        &:after {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          top: 50%;
          left: 50%;
          border-radius: 100%;
          transform: translate(-50%, -50%);
          background-color: $red;
        }

        .ee-event-tracker-pulse {
          position: absolute;
          width: 100%;
          height: 100%;
          border: 3px solid $white;
          border-radius: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0.5;
          animation-duration: 6s;
          animation-iteration-count: infinite;

          &-1 {
            animation-name: ee-event-tracker-pulse-1;
          }

          &-2 {
            animation-name: ee-event-tracker-pulse-2;
          }

          &-3 {
            animation-name: ee-event-tracker-pulse-3;
          }
        }
      }

      .ee-event-tracker-time {
        position: absolute;
        bottom: 7px;
        right: 20px;
        text-align: center;
        color: $white;
        z-index: 10;

        .ee-time-title {
          margin-bottom: 2px;
          font-size: 0.75rem;
          line-height: 1;
          font-weight: 600;
          font-style: italic;
          text-transform: uppercase;
        }

        .ee-time-value {
          @extend .d-flex;
          @extend .flex-row;
          @extend .align-items-center;

          .ee-time-unit-wrapper {
            margin: 0 5px;
          }

          .ee-time-unit {
            width: 45px;
            padding: 7px 5px 0 5px;
            margin-bottom: 4px;
            font-size: 1.6875rem;
            line-height: 1;
            background-color: $opacity-50;
            border-radius: 3px;
          }

          .ee-time-unit-label {
            font-size: 0.5625rem;
            line-height: 1;
            font-style: italic;
            text-transform: uppercase;
          }
        }
      }
    }

    .ee-news {
      width: 100%;

      .ee-news-feed {
        background-color: $opacity-50;
        padding: 20px 40px 40px 40px;

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            @extend %ee-box;
            min-height: 125px;
            padding: 25px 25px 50px 25px;
            margin-bottom: 5px;
            position: relative;
            text-align: left;

            &:hover {
              cursor: pointer;
              background-color: $grey-05;
            }

            h2 {
              margin: 0;
              font-size: 1.25rem;
              line-height: 1.5rem;
              color: $grey-80;
            }

            dl {
              position: absolute;
              bottom: 20px;
              margin: 0;
              font-size: 0.75rem;
              line-height: 1;
              font-weight: 700;
              text-transform: uppercase;

              dt {
                display: inline-block;
                margin-right: 5px;
              }

              dd {
                @extend %ee-news-source;
              }
            }
          }
        }
      }
    }
  }

  .ee-module-hurricane {
    .ee-player-respond-view {
      &-midtown {
        .ee-full-page-background {
          background-color: $blue;
          @include background-image('player/neighborhood-beacon-midtown-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-midtown', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $blue;
            @include background-image('player/neighborhood-beacon-midtown-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-midtown', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-move;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-supplies;
                    font-size: 5rem;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $blue;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-midtown', 'png');
            }
          }
        }
      }

      &-sunnybrook {
        .ee-full-page-background {
          background-color: $yellow;
          @include background-image('player/neighborhood-beacon-sunnybrook-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $yellow;
            @include background-image('player/neighborhood-beacon-sunnybrook-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-move;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-supplies;
                    font-size: 5rem;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $yellow;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
            }
          }
        }
      }

      &-downtown {
        .ee-full-page-background {
          background-color: $purple;
          @include background-image('player/neighborhood-beacon-downtown-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-downtown', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $purple;
            @include background-image('player/neighborhood-beacon-downtown-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-downtown', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-family;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-shelter;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $purple;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-downtown', 'png');
            }
          }
        }
      }

      &-hillside {
        .ee-full-page-background {
          background-color: $blue;
          @include background-image('player/neighborhood-beacon-hillside-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-hillside', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $blue;
            @include background-image('player/neighborhood-beacon-hillside-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-hillside', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-route;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-shelter;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $blue;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-hillside', 'png');
            }
          }
        }
      }

      &-meadowglen {
        .ee-full-page-background {
          background-color: $green;
          @include background-image('player/neighborhood-beacon-meadowglen-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-meadowglen', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $green;
            @include background-image('player/neighborhood-beacon-meadowglen-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-meadowglen', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-supplies;
                    font-size: 5rem;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-move-supplies;
                    font-size: 10rem;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $green;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-meadowglen', 'png');
            }
          }
        }
      }

      &-waterview {
        .ee-full-page-background {
          background-color: $teal;
          @include background-image('player/neighborhood-beacon-waterview-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-waterview', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $teal;
            @include background-image('player/neighborhood-beacon-waterview-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-waterview', 'png');
            }
          }
        }


        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-flood;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-flood;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $teal;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-waterview', 'png');
            }
          }
        }
      }

      .ee-event-tracker-event {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        background-size: 100%;
        background-repeat: repeat-x;
        width: 258px;
        height: 143px;
        margin-left: -129px;
        margin-top: -71px;
        @include background-image('player/tracker-cloud', 'png');
      }

      .ee-event-tracker-topography {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        bottom: 0;
        @include background-image('player/hurricane-topography', 'jpg');
        background-size: 100%;
        width: 901px;
        animation: ee-event-tracker-topography 10000s;
      }

      .ee-popup {
        &-blackout {
          .ee-popup-title {
            h1 {
              &:before {
                @extend .ee-icon-blackout;
              }
            }
          }
        }

        &-flash-flood {
          .ee-popup-title {
            h1 {
              &:before {
                @extend .ee-icon-flood;
              }
            }
          }
        }

        &-gridlock {
          .ee-popup-title {
            h1 {
              &:before {
                @extend .ee-icon-gridlock;
              }
            }
          }
        }

        &-road-closure {
          .ee-popup-title {
            h1 {
              &:before {
                @extend .ee-icon-road-closure;
              }
            }
          }
        }
      }

      .ee-challenge-notification {
        &-blackout {
          &:before {
            @extend .ee-icon-blackout;
          }
        }

        &-flash-flood {
          &:before {
            @extend .ee-icon-flood;
          }
        }

        &-road-closure {
          &:before {
            @extend .ee-icon-road-closure;
          }
        }

        &-gridlock {
          &:before {
            @extend .ee-icon-gridlock;
          }
        }
      }
    }
  }

  .ee-module-flood {
    .ee-player-respond-view {
      &-hillside {
        .ee-full-page-background {
          background-color: $blue;
          @include background-image('player/neighborhood-beacon-hillside-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-hillside', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $blue;
            @include background-image('player/neighborhood-beacon-hillside-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-hillside', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-shelter;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-move-supplies;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $blue;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-hillside', 'png');
            }
          }
        }
      }

      &-meadowglen {
        .ee-full-page-background {
          background-color: $teal;
          @include background-image('player/neighborhood-beacon-meadowglen-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-meadowglen', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $teal;
            @include background-image('player/neighborhood-beacon-meadowglen-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-meadowglen', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-flood;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-flood;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $teal;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-meadowglen', 'png');
            }
          }
        }
      }

      &-downtown {
        .ee-full-page-background {
          background-color: $purple;
          @include background-image('player/neighborhood-beacon-downtown-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-downtown', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $purple;
            @include background-image('player/neighborhood-beacon-downtown-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-downtown', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-hardhat;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-tools;
                    font-size: 4.375rem;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $purple;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-downtown', 'png');
            }
          }
        }
      }

      &-waterview {
        .ee-full-page-background {
          background-color: $yellow;
          @include background-image('player/neighborhood-beacon-waterview-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-waterview', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $yellow;
            @include background-image('player/neighborhood-beacon-waterview-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-waterview', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-run;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-tools;
                    font-size: 4.375rem;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $yellow;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-waterview', 'png');
            }
          }
        }
      }

      &-sunnybrook {
        .ee-full-page-background {
          background-color: $green;
          @include background-image('player/neighborhood-beacon-sunnybrook-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $green;
            @include background-image('player/neighborhood-beacon-sunnybrook-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-run;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-shelter;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $green;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
            }
          }
        }
      }

      &-midtown {
        .ee-full-page-background {
          background-color: $blue;
          @include background-image('player/neighborhood-beacon-midtown-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-midtown', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $blue;
            @include background-image('player/neighborhood-beacon-midtown-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-midtown', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-move-supplies;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-water-block;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $blue;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-midtown', 'png');
            }
          }
        }
      }

      .ee-event-tracker {
        @include background-image('player/phase-respond-flood-tracker', 'png');
      }

      .ee-popup {
        &-explosion {
          .ee-popup-title {
            h1 {
              &:before {
                @extend .ee-icon-challenge-fire;
              }
            }
          }
        }

        &-mapping-glitch {
          .ee-popup-title {
            h1 {
              &:before {
                @extend .ee-icon-challenge-compass;
              }
            }
          }
        }

        &-tech-outage {
          .ee-popup-title {
            h1 {
              &:before {
                @extend .ee-icon-challenge-comms;
              }
            }
          }
        }

        &-bridge-closure {
          .ee-popup-title {
            h1 {
              &:before {
                @extend .ee-icon-challenge-bridge;
              }
            }
          }
        }
      }

      .ee-challenge-notification {
        &-explosion {
          &:before {
            @extend .ee-icon-challenge-fire;
          }
        }

        &-tech-outage {
          &:before {
            @extend .ee-icon-challenge-comms;
          }
        }

        &-bridge-closure {
          &:before {
            @extend .ee-icon-challenge-bridge;
          }
        }

        &-mapping-glitch {
          &:before {
            @extend .ee-icon-challenge-compass;
          }
        }
      }
    }
  }

  .ee-module-earthquake {
    .ee-player-respond-view {
      .ee-splash-view {
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-name: earthquake-shake;
        animation-timing-function: ease-in-out;
      }

      &-midtown {
        .ee-full-page-background {
          background-color: $green;
          @include background-image('player/neighborhood-beacon-midtown-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-midtown', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $green;
            @include background-image('player/neighborhood-beacon-midtown-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-midtown', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-shelter;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-caregivers;
                    font-size: 5rem;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $green;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-midtown', 'png');
            }
          }
        }
      }

      &-hillside {
        .ee-full-page-background {
          background-color: $yellow;
          @include background-image('player/neighborhood-beacon-hillside-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-hillside', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $yellow;
            @include background-image('player/neighborhood-beacon-hillside-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-hillside', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-hardhat;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-row;
                    font-size: 5rem;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $yellow;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-hillside', 'png');
            }
          }
        }
      }

      &-waterview {
        .ee-full-page-background {
          background-color: $teal;
          @include background-image('player/neighborhood-beacon-waterview-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-waterview', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $teal;
            @include background-image('player/neighborhood-beacon-waterview-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-waterview', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-flood;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-flood;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $teal;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-waterview', 'png');
            }
          }
        }
      }

      &-sunnybrook {
        .ee-full-page-background {
          background-color: $blue;
          @include background-image('player/neighborhood-beacon-sunnybrook-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $blue;
            @include background-image('player/neighborhood-beacon-sunnybrook-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-medical-relief;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-run;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $blue;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-sunnybrook', 'png');
            }
          }
        }
      }

      &-downtown {
        .ee-full-page-background {
          background-color: $purple;
          @include background-image('player/neighborhood-beacon-downtown-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-downtown', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $purple;
            @include background-image('player/neighborhood-beacon-downtown-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-downtown', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-fire;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-medical-relief;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $purple;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-downtown', 'png');
            }
          }
        }
      }

      &-meadowglen {
        .ee-full-page-background {
          background-color: $blue;
          @include background-image('player/neighborhood-beacon-meadowglen-background', 'png');

          .ee-player-type {
            @include background-image('player/neighborhood-beacon-meadowglen', 'png');
          }
        }

        .ee-challenge-board-overview {
          header {
            background-color: $blue;
            @include background-image('player/neighborhood-beacon-meadowglen-background', 'png');

            h2 {
              @include background-image('player/neighborhood-beacon-meadowglen', 'png');
            }
          }
        }

        .ee-challenge-board-paths {
          .ee-board-path {
            &-0 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-shelter;
                  }
                }
              }
            }

            &-1 {
              header {
                h3 {
                  &:after {
                    @extend .ee-icon-challenge-route;
                  }
                }
              }
            }
          }
        }

        .ee-popup-tabbed {
          header {
            background-color: $blue;

            .ee-popup-header {
              @include background-image('player/neighborhood-beacon-meadowglen', 'png');
            }
          }
        }
      }

      .ee-event-tracker {
        background-image: image('player/earthquake-map.png');
        background-position: center;

        .ee-event-tracker-pulse {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 50%;
          left: 50%;

          .ee-pulse-center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
            height: 20px;
            border-radius: 100px;
            background: $red;
          }

          .ee-pulse-ring {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 200px;
            height: 200px;
            border-radius: 200px;
            border: 8px solid $red;
            transform: translate(-50%, -50%);
            opacity: 0;
          }

          &-1 {
            transform: translate(50px, 0);

            .ee-pulse-ring {
              &-sm {
                animation: ee-event-tracker-pulse-small 2s 0.1s linear infinite;
              }

              &-md {
                animation: ee-event-tracker-pulse-small 2s 0.5s linear infinite;
              }

              &-lg {
                animation: ee-event-tracker-pulse-small 2s 1s linear infinite;
              }

              &-xl {
                animation: ee-event-tracker-pulse-small 2s 1.5s linear infinite;
              }
            }
          }

          &-2 {
            transform: translate(110px, 40px);

            .ee-pulse-center {
              width: 15px;
              height: 15px;
            }

            .ee-pulse-ring {
              &-sm {
                animation: ee-event-tracker-pulse-small 4s 1s linear infinite;
              }

              &-md {
                animation: ee-event-tracker-pulse-small 4s 1.5s linear infinite;
              }

              &-lg {
                animation: ee-event-tracker-pulse-small 4s 2s linear infinite;
              }

              &-xl {
                animation: ee-event-tracker-pulse-small 4s 2.5s linear infinite;
              }
            }
          }

          &-3 {
            transform: translate(25px, -50px);

            .ee-pulse-center {
              width: 10px;
              height: 10px;
            }

            .ee-pulse-ring {
              &-sm {
                animation: ee-event-tracker-pulse-small 4s 2s linear infinite;
              }

              &-md {
                animation: ee-event-tracker-pulse-small 4s 2.5s linear infinite;
              }

              &-lg {
                animation: ee-event-tracker-pulse-small 4s 3s linear infinite;
              }

              &-xl {
                animation: ee-event-tracker-pulse-small 4s 3.5s linear infinite;
              }
            }
          }
        }

        .ee-event-tracker-event {
          position: absolute;
          bottom: 10px;
          left: 20px;
          color: $white;

          .ee-magnitude-title {
            font-size: 0.75rem;
            line-height: 1;
            font-style: italic;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 2px;
          }

          .ee-magnitude-control {
            @extend .d-flex;
            @extend .flex-row;
            @extend .justify-content-center;
            padding: 2px 5px;
            background-color: $opacity-50;
            border-radius: 3px;
            margin-bottom: 4px;

            .ee-magnitude-value {
              font-size: 1.6875rem;
              line-height: 1;
              height: 27px;
              padding-top: 5px;
            }

            .ee-magnitude-graph {
              width: 75px;
              height: 30px;
              background-image: image('player/earthquake-magnitude.png');
              background-repeat: no-repeat;
              background-size: 75px 30px;
            }
          }

          .ee-magnitude-label {
            font-size: 0.5625rem;
            line-height: 1;
            font-style: italic;
            text-transform: uppercase;
          }
        }
      }

      .ee-popup {
        &-fire {
          .ee-popup-title {
            h1 {
              &:before {
                @extend .ee-icon-fire;
              }
            }
          }
        }

        &-emergency-aid {
          .ee-popup-title {
            h1 {
              &:before {
                @extend .ee-icon-medical-relief;
              }
            }
          }
        }

        &-chasm {
          .ee-popup-title {
            h1 {
              &:before {
                @extend .ee-icon-chasm;
              }
            }
          }
        }

        &-train-derailment {
          .ee-popup-title {
            h1 {
              &:before {
                @extend .ee-icon-train-derailment;
              }
            }
          }
        }
      }

      .ee-challenge-notification {
        &-fire {
          &:before {
            @extend .ee-icon-fire;
          }
        }

        &-emergency-aid {
          &:before {
            @extend .ee-icon-medical-relief;
          }
        }

        &-chasm {
          &:before {
            @extend .ee-icon-chasm;
          }
        }

        &-train-derailment {
          &:before {
            @extend .ee-icon-train-derailment;
          }
        }
      }
    }
  }
}
