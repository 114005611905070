@mixin ee-button($background-color, $border-color, $color, $lighten-on-hover: true) {
  color: $color;
  background-color: $background-color;
  border-color: $border-color;

  &:hover {
    @if $lighten-on-hover {
      color: lighten($color, 15%);
      background-color: lighten($background-color, 15%);
      border-color: lighten($border-color, 15%);
    } @else {
      color: $background-color;
      background-color: $border-color;
    }
  }

  &:active {
    background-color: $white;
    color: $background-color;
  }

  &:disabled,
  &:hover:disabled {
    cursor: default;
    background-color: transparentize($white, 0.5);
    border-color: transparentize($black, 0.85);
    color: transparentize($black, 0.85);
  }

  @if $background-color == transparent {
    &:hover {
      color: $color;
      background-color: transparentize($white, 0.8);
    }

    &:active {
      color: $tint-teal-10;
      background-color: $white;
    }

    &:disabled {
      border-color: $shade-teal-20;
      color: $shade-teal-20;
    }
  }
}

@mixin ee-button-with-text($background-color, $border-color, $color, $lighten-on-hover: true) {
  &:before {
    color: $color;
    background-color: $background-color;
    border-color: $border-color;
  }

  &:hover {
    &:before {
      @if $lighten-on-hover {
        color: lighten($color, 15%);
        background-color: lighten($background-color, 15%);
        border-color: lighten($border-color, 15%);
      } @else {
        color: $background-color;
        background-color: $border-color;
      }
    }
  }

  &:active {
    &:before {
      background-color: $white;
      color: $background-color;
    }
  }

  &:disabled,
  &:hover:disabled {
    &:before {
      cursor: default;
      background-color: transparentize($white, 0.5);
      border-color: transparentize($black, 0.85);
      color: transparentize($black, 0.85);
    }
  }
}

%ee-button {
  font-size: 1rem;
  line-height: 1.125rem;
  font-style: italic;
  font-weight: 800;
  text-transform: uppercase;
  outline: none;
  vertical-align: baseline;
  text-decoration: none;
  user-select: none;
  border-width: 3px;
  border-style: solid;
  border-radius: 999px;

  &:focus {
    box-shadow: 0 0 5px 0 $opacity-25;
  }
}

%ee-button-text {
  padding: 14px 30px 8px 30px;
  font-weight: 700;
}

%ee-button-icon {
  width: 45px;
  height: 45px;
  text-align: center;
  padding: 13px 0 10px 0;
}

%ee-button-text-icon {
  position: relative;
  padding-top: 13px;
  padding-right: 30px;
  padding-bottom: 8px;
  padding-left: 43px;
  font-size: 0.8125rem;
  font-weight: 500;
}

%ee-button-text-icon-pseudo {
  position: absolute;
  top: 50%;
  height: 30px;
  width: 30px;
  margin-top: -15px;
  font-size: 0.8125rem;
  line-height: 24px;
  border-width: 3px;
  border-style: solid;
  border-radius: 999px;
}

%ee-button-text-icon-before {
  left: 3px;
}

%ee-button-link {
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

%ee-button-close {
  @extend %ee-button;
  @extend %ee-button-icon;
  @extend %ee-button-link;
  @include ee-button($red, $white, $white);

  &:before {
    @extend .ee-icon-x;
    position: relative;
    left: 13px;
    top: -3px;
  }
}

%ee-button-back {
  @extend %ee-button;
  @extend %ee-button-icon;
  @extend %ee-button-link;
  @include ee-button($teal, $white, $white);

  &:before {
    @extend .ee-icon-chevron-left;
    position: relative;
    top: -3px;
    right: 1px;
  }
}

%ee-button-info {
  @extend %ee-button;
  @extend %ee-button-text-icon;
  @extend %ee-button-link;
  @include ee-button($white, $teal, $teal, false);

  &:before {
    @extend .ee-icon-i;
    @extend %ee-button-text-icon-pseudo;
    @extend %ee-button-text-icon-before;
    border-color: $teal;
    background-color: lighten($teal, 10%);
    color: $white;
  }
}

%ee-button-mute {
  @extend %ee-button;
  @extend %ee-button-icon;
  @extend %ee-button-link;
  @include ee-button($teal, $teal, $white);
  position: relative;
  padding-top: 11px;

  &:before {
    @extend .ee-icon-speaker-mute;
    font-size: 1.25rem;
  }
}

%ee-button-settings {
  @extend %ee-button;
  @extend %ee-button-icon;
  @extend %ee-button-link;
  @include ee-button($teal, $teal, $white);
  position: relative;
  padding-top: 11px;

  &:before {
    @extend .ee-icon-gear;
    font-size: 1.25rem;
  }
}

%ee-button-with-text {
  @extend %ee-button-link;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 700;
  font-style: italic;

  &:before {
    @extend %ee-button;
    @extend %ee-button-icon;
    font-size: 1.25rem;
    font-style: normal;
    padding-top: 11px;
    margin-right: 10px;
  }
}

