.Toastify__toast-container--top-center {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0;
  padding: 0;

  .Toastify__toast {
    font-family: $font-family-sans-serif;
    font-size: 1.375rem;
    line-height: 1.3125rem;
    font-weight: 500;
    font-style: italic;
    margin: 0;
    cursor: default;
    min-height: auto;
    border-radius: 0;
    box-shadow: none;
    display: block;
    padding: 15px 40px 10px 80px;

    &:before,
    &:after {
      position: absolute;
      top: 13px;
      left: 40px;
      width: 40px;
      font-size: 2rem;
      text-align: center;
      display: block;
    }

    &:before {
      @extend .ee-icon-warning-sign;
    }

    &:after {
      @extend .ee-icon-warning-icon;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }

    &--error {
      background: $red;

      &:before {
        color: lighten($red, 15%);
      }

      &:after {
        color: $white;
      }
    }

    &--success {
      background: $dark-yellow;
      color: $black;

      &:before {
        color: lighten($dark-yellow, 15%);
      }

      &:after {
        color: $black;
      }
    }

    .Toastify__toast-body {
      margin: 0;
    }
  }
}
